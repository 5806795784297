import { Injectable } from '@angular/core';
import { getAuth } from '@firebase/auth';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocsFromServer,
    getFirestore,
    query,
    updateDoc,
    where,
} from '@firebase/firestore';
import { from, Observable } from 'rxjs';
import { Client } from './clients.model';

@Injectable({
    providedIn: 'root',
})
export class ClientsDataService {
    private db = getFirestore();
    private auth = getAuth();
    private COLLECTION_NAME = 'clients';

    constructor() {}

    getClients(): Observable<Client[]> {
        const clientQuery = query(collection(this.db, this.COLLECTION_NAME), where('deleted', '==', false));
        return from(
            getDocsFromServer(clientQuery).then((querySnapshot) => {
                const clients: Client[] = [];
                querySnapshot.forEach((doc) => {
                    const { name, mobileNo, salesPerson, amount, contractorId, createdBy, deleted } = doc.data();
                    clients.push({ id: doc.id, name, mobileNo, salesPerson, amount, contractorId, createdBy, deleted });
                });
                return clients;
            })
        );
    }

    addClient(
        name: string,
        mobileNo: string,
        salesPerson: string,
        amount: number,
        contractorId: string
    ): Observable<Client> {
        const user = this.auth.currentUser;
        return from(
            addDoc(collection(this.db, this.COLLECTION_NAME), {
                name,
                mobileNo,
                salesPerson,
                amount,
                contractorId,
                createdBy: user.uid,
                deleted: false,
            }).then((docRef) => {
                return {
                    id: docRef.id,
                    name,
                    mobileNo,
                    salesPerson,
                    amount,
                    contractorId,
                    createdBy: user.uid,
                    deleted: false,
                };
            })
        );
    }

    updateClient(
        id: string,
        name: string,
        mobileNo: string,
        salesPerson: string,
        amount: number,
        contractorId: string
    ): Observable<Client> {
        const user = this.auth.currentUser;
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        return from(
            updateDoc(docRef, { name, mobileNo, salesPerson, amount, contractorId }).then((_) => {
                return {
                    id,
                    name,
                    mobileNo,
                    salesPerson,
                    amount,
                    contractorId,
                    createdBy: user.uid,
                    deleted: false,
                };
            })
        );
    }

    deleteClient(id: string): Observable<boolean> {
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        return from(deleteDoc(docRef).then(() => true));
    }
}
