import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientsService } from 'app/core/clients/clients.service';
import { Contractor } from 'app/core/contractors/contractors.model';
import { ContractorsService } from 'app/core/contractors/contractors.service';
import { Item } from 'app/core/inventory/inventory.model';
import { InventoryService } from 'app/core/inventory/inventory.service';
import { PointEntriesService } from 'app/core/point-entries/point-entries.service';
import { UserDetails } from 'app/core/user/store/user.model';
import { UserService } from 'app/core/user/user.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-create-point-entries',
    templateUrl: './create-point-entries.component.html',
    styleUrls: ['./create-point-entries.component.scss'],
})
export class CreatePointEntriesComponent implements OnInit {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    allContractors: Contractor[];
    allItems: Item[];
    addContractorPointFrom: FormGroup;

    constructor(
        private readonly inventoryService: InventoryService,
        private readonly contractorsService: ContractorsService,
        private readonly pointEntriesService: PointEntriesService
    ) {
        this.addContractorPointFrom = new FormGroup({
            contractor: new FormControl('', [Validators.required]),
            siteName: new FormControl('', [Validators.required]),
            date: new FormControl('', [Validators.required]),
            item: new FormControl('', [Validators.required]),
            quantity: new FormControl('', [Validators.required]),
            points: new FormControl({ value: 0, disabled: true }, [Validators.required]),
            totalPoints: new FormControl({ value: 0, disabled: true }, [Validators.required]),
        });
    }

    ngOnInit(): void {
        this.inventoryService.allItems$.pipe(takeUntil(this.destroyed$)).subscribe((items) => {
            this.allItems = items;
        });
        this.contractorsService.allContractors$.pipe(takeUntil(this.destroyed$)).subscribe((contractors) => {
            this.allContractors = contractors;
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    updatePoints(): void {
        const item = this.allItems.find((i) => i.id === this.addContractorPointFrom.controls['item'].value);
        this.addContractorPointFrom.controls['points'].patchValue(item.points);
    }

    countTotalPoints(quantity: number) {
        if (!isNaN(quantity)) {
            const points = this.addContractorPointFrom.controls['points'].value;
            this.addContractorPointFrom.controls['totalPoints'].patchValue(Number(points * quantity).toFixed(2));
        }
    }

    addContractorPoint(): void {
        this.addContractorPointFrom.disable();
        const { contractor, siteName, date, item, quantity, points, totalPoints } = this.addContractorPointFrom.value;
        const itemFromArray = this.allItems.find((i) => i.id === item);
        const contractorFromArray = this.allContractors.find((i) => i.id === contractor);
        this.pointEntriesService.addPointEntry(
            {
                contractorId: contractor,
                siteName,
                date: new Date(date).toISOString(),
                itemId: item,
                quantity,
                points,
                totalPoints: +totalPoints,
            },
            itemFromArray,
            contractorFromArray
        );
    }
}
