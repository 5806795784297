import { Route } from '@angular/router';
import { AuthenticationGuard } from './core/authentication/guard/authentication.guard';
import { LayoutComponent } from './layout/layout.component';

export const appRoutes: Route[] = [
    {
        path: 'auth',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        loadChildren: () =>
            import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
    },
    {
        path: 'home',
        component: LayoutComponent,
        canActivate: [AuthenticationGuard],
        data: {
            layout: 'classy',
        },
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: '**',
        redirectTo: 'auth',
        pathMatch: 'full',
    },
];
