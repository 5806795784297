import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserRoles } from '../authentication/store/authentication.model';
import { StockEntryType } from '../inventory/inventory.model';
import { InventoryService } from '../inventory/inventory.service';
import { InvoicesDataService } from './invoices.data.service';
import { Invoice, InvoiceReceive, InvoiceVariables } from './invoices.model';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class InvoicesService {
    private invoices: Invoice[] = [];
    private allInvoices: BehaviorSubject<Invoice[]> = new BehaviorSubject(
        this.invoices
    );
    private isInvoiceLoading: BehaviorSubject<boolean> = new BehaviorSubject(
        true
    );

    allInvoices$ = this.allInvoices.asObservable();
    isInvoiceLoading$ = this.isInvoiceLoading.asObservable();

    constructor(
        private readonly invoicesDataService: InvoicesDataService,
        private readonly inventoryService: InventoryService,
        private readonly authenticationService: AuthenticationService
    ) {}

    getAllInvoices(): void {
        if (!this.invoices.length) {
            this.isInvoiceLoading.next(true);
            this.invoicesDataService
                .getInvoices()
                .pipe(first())
                .subscribe((invoices) => {
                    this.invoices = _.sortBy(invoices, (x) => [
                        x.invoiceNo.toLowerCase(),
                        x.date,
                    ]);
                    this.allInvoices.next(this.invoices);
                    this.isInvoiceLoading.next(false);
                });
        }
    }

    getInvoiceById(invoiceId: string): Observable<Invoice> {
        return of(this.invoices.find((invoice) => invoice.id === invoiceId));
    }

    addInvoice(input: InvoiceVariables, file?: File): void {
        if (file) {
            this.invoicesDataService
                .uploadFile(file, input.invoiceNo, input.client)
                .pipe(first())
                .subscribe(([fileName, downloadUrl]) => {
                    input.fileName = fileName;
                    input.filePath = downloadUrl;
                    this.invoicesDataService
                        .addInvoice(input)
                        .pipe(first())
                        .subscribe((invoice) => {
                            this.updateInvoiceArrayAndItemStock(invoice);
                        });
                });
        } else {
            this.invoicesDataService
                .addInvoice(input)
                .pipe(first())
                .subscribe((invoice) => {
                    this.updateInvoiceArrayAndItemStock(invoice);
                });
        }
    }

    updateInvoiceArrayAndItemStock(invoice: Invoice): void {
        this.invoices.push(invoice);
        this.invoices = _.sortBy(this.invoices, (x) => x.invoiceNo);
        this.allInvoices.next(this.invoices);
        invoice.invoiceItems.forEach((item) => {
            this.inventoryService.addInventoryStockEntry({
                itemId: item.itemId,
                quantity: item.quantity,
                type: StockEntryType.Outward,
                dateTime: invoice.date,
                remarks: `For invoice# ${invoice.invoiceNo}`,
            });
            // this.inventoryService.updateItemQuantity(item.itemId, -item.quantity);
        });
    }

    updateInvoice(id: string, input: InvoiceVariables): void {
        this.invoicesDataService
            .updateInvoice(id, input)
            .pipe(first())
            .subscribe((updatedInvoice) => {
                const existingInvoiceIndex = this.invoices.findIndex(
                    (invoice) => invoice.id === id
                );
                this.invoices[existingInvoiceIndex] = updatedInvoice;
                this.invoices = _.sortBy(this.invoices, (x) => [
                    x.invoiceNo.toLowerCase(),
                    x.date,
                ]);
                this.allInvoices.next(this.invoices);
            });
    }

    deleteInvoice(id: string): void {
        this.invoicesDataService
            .deleteInvoice(id)
            .pipe(first())
            .subscribe((deleted) => {
                if (deleted) {
                    this.updateDeletedInvoice(id);
                }
            });
    }

    deleteBulkInvoices(ids: string[]): void {
        this.invoicesDataService
            .deleteBulkInvoices(ids)
            .pipe(first())
            .subscribe((deleted) => {
                if (deleted) {
                    ids.forEach((id) => {
                        this.updateDeletedInvoice(id);
                    });
                }
            });
    }

    private updateDeletedInvoice(id: string): void {
        const existingInvoiceIndex = this.invoices.findIndex(
            (invoice) => invoice.id === id
        );
        if (existingInvoiceIndex > -1) {
            this.invoices[existingInvoiceIndex].invoiceItems.forEach((item) => {
                this.inventoryService.addInventoryStockEntry({
                    itemId: item.itemId,
                    quantity: item.quantity,
                    type: StockEntryType.Inward,
                    dateTime: new Date().toISOString(),
                    remarks: `Revert for invoice# ${this.invoices[existingInvoiceIndex].invoiceNo}`,
                });
            });
            this.invoices.splice(existingInvoiceIndex, 1);
            this.invoices = _.sortBy(this.invoices, (x) => [
                x.invoiceNo.toLowerCase(),
                x.date,
            ]);
            this.allInvoices.next(this.invoices);
        }
    }

    markInvoiceAsPaid(id: string): void {
        this.invoicesDataService
            .markInvoiceAsPaid(id)
            .pipe(first())
            .subscribe((status) => {
                if (status) {
                    const existingInvoiceIndex = this.invoices.findIndex(
                        (invoice) => invoice.id === id
                    );
                    this.invoices[existingInvoiceIndex].paid = true;
                    this.invoices = _.sortBy(this.invoices, (x) => [
                        x.invoiceNo.toLowerCase(),
                        x.date,
                    ]);
                    this.allInvoices.next(this.invoices);
                }
            });
    }

    markInvoiceAsAuthorized(id: string): void {
        const [role] = this.authenticationService.getUserUidAndRole();
        this.invoicesDataService
            .markInvoiceAsAuthorize(id)
            .pipe(first())
            .subscribe((status) => {
                if (status) {
                    const existingInvoiceIndex = this.invoices.findIndex(
                        (invoice) => invoice.id === id
                    );
                    if (
                        role === UserRoles.ADMIN ||
                        role === UserRoles.SALESADMIN
                    ) {
                        this.invoices[existingInvoiceIndex].authorizedByAdmin =
                            true;
                    } else {
                        this.invoices[existingInvoiceIndex].authorizedBySales =
                            true;
                    }
                    this.invoices = _.sortBy(this.invoices, (x) => [
                        x.invoiceNo.toLowerCase(),
                        x.date,
                    ]);
                    this.allInvoices.next(this.invoices);
                }
            });
    }

    addInvoiceReceivedAmount(invoiceId: string, data: InvoiceReceive): void {
        const existingInvoiceIndex = this.invoices.findIndex(
            (invoice) => invoice.id === invoiceId
        );
        const invoiceReceive =
            this.invoices[existingInvoiceIndex].receivedAmounts;
        invoiceReceive.push(data);
        this.invoicesDataService
            .addInvoiceReceivedAmount(invoiceId, invoiceReceive)
            .pipe(first())
            .subscribe((status) => {
                if (status) {
                    this.invoices[existingInvoiceIndex].receivedAmounts =
                        invoiceReceive;
                    this.invoices = _.sortBy(this.invoices, (x) => [
                        x.invoiceNo.toLowerCase(),
                        x.date,
                    ]);
                    this.allInvoices.next(this.invoices);
                }
            });
    }
}
