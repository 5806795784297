import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from 'app/core/core.state';
import { AuthenticationDataService } from '../authentication.data.service';
import {
    ActionLoginWithUsernameAndPasswordFailed,
    ActionLoginWithUsernameAndPasswordStart,
    ActionLoginWithUsernameAndPasswordSuccess,
    ActionSetAuthError,
    ActionSetToken,
} from './authentication.actions';
import { catchError, filter, first, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../authentication.service';
import { ActionGetUserDetailsByUserIdStart } from 'app/core/user/store/user.actions';
import { selectRedirectUrl } from './authentication.selector';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<AppState>,
        private readonly router: Router,
        private readonly authenticationDataService: AuthenticationDataService,
        private readonly authenticationService: AuthenticationService
    ) {}

    loginWithUsernameAndPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionLoginWithUsernameAndPasswordStart),
            filter(({ username, password }) => !!username && !!password),
            first(),
            switchMap(({ username, password }) => {
                const email = `${username}@${environment.domainName}`;
                return this.authenticationDataService.loginWithEmailAndPassword(email, password).pipe(
                    first(),
                    switchMap((user) => {
                        return this.authenticationDataService.fetchUserAuthToken().pipe(
                            first(),
                            switchMap((token) => [
                                ActionLoginWithUsernameAndPasswordSuccess(),
                                ActionSetToken({ token }),
                                ActionGetUserDetailsByUserIdStart({ userId: user.user.uid }),
                            ]),
                            catchError((error) => [ActionSetAuthError({ authError: error })])
                        );
                    }),
                    catchError((error) => [ActionLoginWithUsernameAndPasswordFailed({ authError: error })])
                );
            })
        )
    );

    loginWithUsernameAndPasswordSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActionLoginWithUsernameAndPasswordSuccess),
                withLatestFrom(this.store$.pipe(select(selectRedirectUrl))),
                first(),
                tap(([, redirectUrl]) => {
                    const navigateUrl = redirectUrl || '/home';
                    this.authenticationService.setRedirectUrl(undefined);
                    this.router.navigateByUrl(navigateUrl);
                })
            ),
        { dispatch: false }
    );
}
