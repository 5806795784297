import { Injectable } from '@angular/core';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocsFromServer,
    getFirestore,
    query,
    updateDoc,
    where,
} from '@firebase/firestore';
import { environment } from 'environments/environment';
import { getAuth } from 'firebase/auth';
import { from, Observable } from 'rxjs';
import { HttpClientService } from '../http/http-client.service';
import { Contractor } from './contractors.model';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ContractorsDataService {
    private db = getFirestore();
    private auth = getAuth();
    private COLLECTION_NAME = 'contractors';

    constructor(private readonly httpClientService: HttpClientService) {}

    getContractors(): Observable<Contractor[]> {
        const contractorQuery = query(collection(this.db, this.COLLECTION_NAME), where('deleted', '==', false));
        return from(
            getDocsFromServer(contractorQuery).then((querySnapshot) => {
                const contractors: Contractor[] = [];
                querySnapshot.forEach((doc) => {
                    const { name, mobileNo, points, createdBy } = doc.data();
                    contractors.push({ id: doc.id, name, mobileNo, points: Math.round(points), createdBy });
                });
                return contractors;
            })
        );
    }

    addContractor(name: string, mobileNo: string, points: number): Observable<Contractor> {
        const user = this.auth.currentUser;
        return from(
            addDoc(collection(this.db, this.COLLECTION_NAME), {
                name,
                mobileNo,
                points,
                createdBy: user.uid,
                deleted: false,
            }).then((docRef) => {
                return {
                    id: docRef.id,
                    name,
                    mobileNo,
                    points,
                    createdBy: user.uid,
                };
            })
        );
    }

    updateContractor(id: string, name: string, mobileNo: string, points: number): Observable<Contractor> {
        const user = this.auth.currentUser;
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        return from(
            updateDoc(docRef, { name, mobileNo, points }).then((_) => {
                return {
                    id,
                    name,
                    mobileNo,
                    points,
                    createdBy: user.uid,
                    deleted: false,
                };
            })
        );
    }

    deleteContractor(id: string): Observable<boolean> {
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        // TODO: Don't delete complete doc
        return from(deleteDoc(docRef).then(() => true));
    }

    sendContractorSms(mobile: string, item: string, quantity: number, date: string): Observable<boolean> {
        const url = environment.serviceUrl + environment.api.sendsms;
        const data = {
            message: `Thank you for using/buying ${item}. A sales of ${quantity} (Units) have been registered on ${moment(
                date
            ).format('DD/MM/YY')}. - Shree Shakti, Madhapar.`,
            mobileNo: mobile,
        };
        return this.httpClientService.post(url, data);
    }

    sendContractorInvoiceSms(
        mobile: string,
        invoiceNo: string,
        quantity: number,
        date: string,
        clientName: string
    ): Observable<boolean> {
        const url = environment.serviceUrl + environment.api.sendsms;
        const data = {
            message: `A sale of ${quantity} (Units) has been registered for invoice #${invoiceNo} on ${moment(
                date
            ).format('DD/MM/YY')} for your client ${clientName}. - Shree Shakti, Madhapar.`,
            mobileNo: mobile,
        };
        return this.httpClientService.post(url, data);
    }
}