import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'app/core/clients/clients.model';
import { ClientsService } from 'app/core/clients/clients.service';
import { Contractor } from 'app/core/contractors/contractors.model';
import { ContractorsService } from 'app/core/contractors/contractors.service';
import { UserDetails } from 'app/core/user/store/user.model';
import { UserService } from 'app/core/user/user.service';
import { combineLatest, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-update-client',
    templateUrl: './update-client.component.html',
    styleUrls: ['./update-client.component.scss'],
})
export class UpdateClientComponent implements OnInit {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    allUsers: UserDetails[];
    allContractors: Contractor[];
    updateClientForm: FormGroup;
    client: Client;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Client,
        private readonly userService: UserService,
        private readonly clientsService: ClientsService,
        private readonly contractorsService: ContractorsService
    ) {
        this.updateClientForm = new FormGroup({
            name: new FormControl('', [Validators.required]),
            mobileNo: new FormControl('', [Validators.required]),
            amount: new FormControl(0, [Validators.required]),
            salesPerson: new FormControl('', [Validators.required]),
            contractorId: new FormControl(''),
        });
    }

    ngOnInit(): void {
        combineLatest([
            this.clientsService.getClientById(this.data.id),
            this.userService.allUsers$,
            this.contractorsService.allContractors$,
        ])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([client, users, contractors]) => {
                this.allUsers = users;
                this.client = client;
                this.allContractors = contractors;
                this.updateClientForm.patchValue({
                    name: client.name,
                    mobileNo: client.mobileNo,
                    amount: client.amount,
                    salesPerson: client.salesPerson,
                    contractorId: client.contractorId || '',
                });
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    updateClient(): void {
        this.updateClientForm.disable();
        const { name, mobileNo, salesPerson, amount, contractorId } = this.updateClientForm.value;
        const { id } = this.client;
        this.clientsService.updateClient(
            id,
            name,
            mobileNo,
            salesPerson,
            Number.parseFloat(amount),
            contractorId || ''
        );
    }
}
