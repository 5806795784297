import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseAlertModule } from '@fuse/components/alert';
import { FuseCardModule } from '@fuse/components/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CreateClientComponent } from './components/create-client/create-client.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { CreateContractorComponent } from './components/create-contractor/create-contractor.component';
import { CreateInvoiceComponent } from './components/create-invoice/create-invoice.component';
import { UpdateClientComponent } from './components/update-client/update-client.component';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { UpdateContractorComponent } from './components/update-contractor/update-contractor.component';
import { CreateFolderComponent } from './components/create-folder/create-folder.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { CreateInventoryItemComponent } from './components/create-inventory-item/create-inventory-item.component';
import { CreateInventoryStockComponent } from './components/create-inventory-stock/create-inventory-stock.component';
import { UpdateInventoryItemComponent } from './components/update-inventory-item/update-inventory-item.component';
import { CreatePointEntriesComponent } from './components/create-point-entries/create-point-entries.component';
import { UpdatePointEntriesComponent } from './components/update-point-entries/update-point-entries.component';
import { UpdateInvoiceComponent } from './components/update-invoice/update-invoice.component';
import { InvoiceReceiveComponent } from './components/invoice-receive/invoice-receive.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatMenuModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatRadioModule,
        FuseCardModule,
        FuseAlertModule,
        FuseConfirmationModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatMenuModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatRadioModule,
        FuseCardModule,
        FuseAlertModule,
        FuseConfirmationModule,
    ],
    declarations: [
        CreateClientComponent,
        CreateContractorComponent,
        CreateInvoiceComponent,
        UpdateClientComponent,
        UpdateContractorComponent,
        CreateFolderComponent,
        CreateUserComponent,
        CreateInventoryItemComponent,
        CreateInventoryStockComponent,
        UpdateInventoryItemComponent,
        CreatePointEntriesComponent,
        UpdatePointEntriesComponent,
        UpdateInvoiceComponent,
        InvoiceReceiveComponent,
    ],
    entryComponents: [CreateClientComponent, CreateContractorComponent, CreateInvoiceComponent],
})
export class SharedModule {}
