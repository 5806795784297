import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getAuth } from 'firebase/auth';
import { AppState } from '../core.state';
import {
    ActionLoginWithUsernameAndPasswordStart,
    ActionSetAuthError,
    ActionSetRedirectUrl,
    ActionSetToken,
} from './store/authentication.actions';
import {
    selectAuthError,
    selectAuthToken,
    selectIsAuthenticating,
    selectIsLoggedIn,
} from './store/authentication.selector';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private auth = getAuth();

    isAuthenticating$ = this.store$.pipe(select(selectIsAuthenticating));
    isLoggedIn$ = this.store$.pipe(select(selectIsLoggedIn));
    authToken$ = this.store$.pipe(select(selectAuthToken));
    authError$ = this.store$.pipe(select(selectAuthError));

    constructor(private readonly store$: Store<AppState>) {}

    loginWithUsernameAndPassword(username: string, password: string): void {
        this.store$.dispatch(ActionLoginWithUsernameAndPasswordStart({ username, password }));
    }

    setRedirectUrl(redirectUrl: string): void {
        this.store$.dispatch(ActionSetRedirectUrl({ redirectUrl }));
    }

    setUserAuthToken(token: string): void {
        this.store$.dispatch(ActionSetToken({ token }));
    }

    setAuthenticationError(error: string): void {
        this.store$.dispatch(ActionSetAuthError({ authError: error }));
    }

    getUserUidAndRole(): string[] {
        const user = this.auth.currentUser;
        return user.uid.split('_');
    }

    logout(): void {
        this.auth.signOut();
    }
}
