import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AppState } from '../core.state';
import { ActionGetAllUsersDetailsStart, ActionGetUserDetailsByUserIdStart } from './store/user.actions';
import { UserDetails, UserFormData } from './store/user.model';
import { selectCurrentUser } from './store/user.selector';
import { UserDataService } from './user.data.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private users: UserDetails[] = [];

    private allUsers: BehaviorSubject<UserDetails[]> = new BehaviorSubject(this.users);
    private isUsersLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);

    allUsers$ = this.allUsers.asObservable();
    isUsersLoading$ = this.isUsersLoading.asObservable();
    currentUser$ = this.store$.pipe(select(selectCurrentUser));

    constructor(private readonly store$: Store<AppState>, private readonly userDataService: UserDataService) {}

    getUserDetailsByUserId(userId: string): void {
        this.store$.dispatch(ActionGetUserDetailsByUserIdStart({ userId }));
    }

    getAllUsersDetails(): void {
        if (!this.users.length) {
            this.isUsersLoading.next(true);
            this.userDataService
                .getAllUsersDetails()
                .pipe(first())
                .subscribe((data) => {
                    this.users = data;
                    this.allUsers.next(this.users);
                    this.isUsersLoading.next(false);
                });
        }
    }

    addNewUser(input: UserFormData): void {
        this.userDataService
            .addNewUser(input)
            .pipe(first())
            .subscribe((user) => {
                this.users.push(user);
                this.allUsers.next(this.users);
            });
    }
}
