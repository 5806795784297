import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientsService } from 'app/core/clients/clients.service';
import { InventoryService } from 'app/core/inventory/inventory.service';
import {
    Invoice,
    InvoiceType,
    InvoiceVariables,
} from 'app/core/invoices/invoices.model';
import { InvoicesService } from 'app/core/invoices/invoices.service';
import moment from 'moment';
import { combineLatest, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateClientComponent } from '../create-client/create-client.component';

@Component({
    selector: 'app-update-invoice',
    templateUrl: './update-invoice.component.html',
    styleUrls: ['./update-invoice.component.scss'],
})
export class UpdateInvoiceComponent implements OnInit {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    addNewInvoiceForm: FormGroup;
    days = new Array(120);
    fileToUpload: File = null;
    uploadBtnText = 'Upload Challen';

    allClients = [];
    allItems = [];
    invoiceType = [InvoiceType.Sales, InvoiceType.Purchase];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Invoice,
        private readonly clientsService: ClientsService,
        private readonly invoicesService: InvoicesService,
        private readonly inventoryService: InventoryService,
        private readonly formBuilder: FormBuilder,
        public readonly dialog: MatDialog
    ) {
        this.addNewInvoiceForm = this.formBuilder.group({
            invoiceNo: ['', [Validators.required]],
            invoiceType: [this.invoiceType[0], [Validators.required]],
            date: ['', [Validators.required]],
            dueDate: ['', [Validators.required]],
            clientId: ['', [Validators.required]],
            remarks: [''],
            items: this.formBuilder.array([this.addMoreInvoiceItem()]),
        });
    }

    ngOnInit(): void {
        combineLatest([
            this.clientsService.allClients$,
            this.inventoryService.allItems$,
        ])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([allClients, allItems]) => {
                this.allClients = allClients;
                this.allItems = allItems;
                const currentDate = moment(this.data.date, 'DD-MM-YYYY');
                const currentDueDate = moment(this.data.dueDate, 'DD-MM-YYYY');
                const diffDays = currentDueDate.diff(currentDate, 'days');
                this.addNewInvoiceForm = this.formBuilder.group({
                    invoiceNo: [this.data.invoiceNo, [Validators.required]],
                    invoiceType: [
                        this.data.invoiceType || this.invoiceType[0],
                        [Validators.required],
                    ],
                    date: [
                        currentDate.format('YYYY-MM-DD'),
                        [Validators.required],
                    ],
                    dueDate: [diffDays, [Validators.required]],
                    clientId: [this.data.clientId, [Validators.required]],
                    remarks: [this.data.remarks],
                    items: this.formBuilder.array(
                        this.data.invoiceItems.map((item) =>
                            this.formBuilder.group({
                                itemId: [item.itemId, [Validators.required]],
                                quantity: [
                                    item.quantity,
                                    [Validators.required],
                                ],
                                price: [item.price, [Validators.required]],
                                totalPrice: [
                                    { value: item.totalPrice, disabled: true },
                                    [Validators.required],
                                ],
                            })
                        )
                    ),
                });
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    addMoreItem(): void {
        this.itemsInvoiceArray.push(this.addMoreInvoiceItem());
    }

    removeItemInvoice(index: number): void {
        if (this.itemsInvoiceArray.length > 1) {
            this.itemsInvoiceArray.removeAt(index);
        }
    }

    addMoreInvoiceItem(): FormGroup {
        return this.formBuilder.group({
            itemId: ['', [Validators.required]],
            quantity: ['', [Validators.required]],
            price: [0, [Validators.required]],
            totalPrice: [{ value: 0, disabled: true }, [Validators.required]],
        });
    }

    get itemsInvoiceArray(): FormArray {
        return <FormArray>this.addNewInvoiceForm.get('items');
    }

    countInvoicePerItemTotalFromQuantity(
        quantity: number,
        parentIndex: number
    ) {
        if (!isNaN(quantity)) {
            const price =
                this.itemsInvoiceArray.controls[parentIndex].value.price;
            // TODO: Look at this area later
            // (this.itemsInvoiceArray.controls[parentIndex] as any).controls.price.patchValue(price);
            (
                this.itemsInvoiceArray.controls[parentIndex] as any
            ).controls.totalPrice.patchValue(price * quantity);
        }
    }

    countInvoicePerItemTotalFromPrice(price: number, parentIndex: number) {
        if (!isNaN(price)) {
            const quantity =
                this.itemsInvoiceArray.controls[parentIndex].value.quantity;
            (
                this.itemsInvoiceArray.controls[parentIndex] as any
            ).controls.totalPrice.patchValue(price * quantity);
        }
    }

    handleFileInput(files: FileList) {
        if (files[0].type === 'image/jpeg' || files[0].type === 'image/png') {
            this.fileToUpload = files[0];
            this.uploadBtnText = this.fileToUpload.name;
        } else {
            alert('Please select JPEG or PNG file!');
        }
    }

    get totalQuantityAndAmount(): { quantity: number; amount: number } {
        const { items } = this.addNewInvoiceForm.value;
        let amount = 0;
        let quantity = 0;
        items.forEach((item) => {
            if (
                !isNaN(Number.parseFloat(item.price)) &&
                !isNaN(Number.parseFloat(item.quantity))
            ) {
                amount +=
                    Number.parseFloat(item.price) *
                    Number.parseFloat(item.quantity);
                quantity += Number.parseFloat(item.quantity);
            }
        });
        return {
            quantity,
            amount,
        };
    }

    addInvoice(): void {
        this.addNewInvoiceForm.disable();
        const {
            invoiceNo,
            date,
            dueDate,
            clientId,
            remarks,
            items,
            invoiceType,
        } = this.addNewInvoiceForm.value;
        let amount = 0;
        let oldAmount = 0;
        this.data.invoiceItems.forEach((item) => {
            oldAmount += item.totalPrice;
        });
        items.forEach((item) => {
            amount += item.totalPrice;
            item.quantity = Number.parseFloat(item.quantity);
            item.price = Number.parseFloat(item.price);
        });
        const input: InvoiceVariables = {
            amount,
            authorizedByAdmin: this.data.authorizedByAdmin,
            authorizedBySales: this.data.authorizedBySales,
            client: clientId,
            date: new Date(date).toISOString(),
            dueDate: moment(date).add(dueDate, 'days').toISOString(),
            fileName: this.data.fileName,
            filePath: this.data.filePath,
            invoiceNo,
            invoiceItems: items,
            paid: this.data.paid,
            remarks,
            receivedAmounts: this.data.receivedAmounts,
            invoiceType,
        };
        // if (this.data.clientId !== clientId) {
        //     this.clientsService.updateClientAmount(this.data.clientId, 0, oldAmount);
        //     this.clientsService.updateClientAmount(clientId, oldAmount);
        // } else {
        //     this.clientsService.updateClientAmount(clientId, amount, oldAmount);
        // }
        this.invoicesService.updateInvoice(this.data.id, input);
    }

    public openCreateClientDialog(): void {
        this.dialog.open(CreateClientComponent, {
            disableClose: true,
        });
    }
}
