import * as AuthActions from '../authentication/store/authentication.actions';
import { Action } from '@ngrx/store';

export function clearState(reducer) {
    return (state, action: Action) => {
        // if (action.type === AuthActions.Logout.type) {
        //     state = undefined;
        // }
        return reducer(state, action);
    };
}
