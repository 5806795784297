import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contractor } from 'app/core/contractors/contractors.model';
import { ContractorsService } from 'app/core/contractors/contractors.service';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'app-update-contractor',
    templateUrl: './update-contractor.component.html',
    styleUrls: ['./update-contractor.component.scss'],
})
export class UpdateContractorComponent implements OnInit {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    updateContractorForm: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Contractor,
        private readonly contractorsService: ContractorsService
    ) {
        this.updateContractorForm = new FormGroup({
            name: new FormControl('', [Validators.required]),
            mobileNo: new FormControl('', [Validators.required]),
        });
    }

    ngOnInit(): void {
        this.updateContractorForm.patchValue({
            name: this.data.name,
            mobileNo: this.data.mobileNo,
        });
    }

    updateContractor(): void {
        this.updateContractorForm.disable();
        const { name, mobileNo } = this.updateContractorForm.value;
        const { id, points } = this.data;
        this.contractorsService.updateContractor(id, name, mobileNo, points);
    }
}
