import { Injectable } from '@angular/core';
import { getAuth } from '@firebase/auth';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocsFromServer,
    getFirestore,
    query,
    updateDoc,
    where,
} from '@firebase/firestore';
import { from, Observable } from 'rxjs';
import { PointEntries, PointEntriesVariables } from './point-entries.model';

@Injectable({
    providedIn: 'root',
})
export class PointEntriesDataService {
    private db = getFirestore();
    private auth = getAuth();
    private COLLECTION_NAME = 'pointEntries';

    constructor() {}

    getPointEntries(contractorId: string): Observable<PointEntries[]> {
        const pointEntriesQuery = query(
            collection(this.db, this.COLLECTION_NAME),
            where('contractorId', '==', contractorId)
        );
        return from(
            getDocsFromServer(pointEntriesQuery).then((querySnapshot) => {
                const pointEntries: PointEntries[] = [];
                querySnapshot.forEach((doc) => {
                    const {
                        date,
                        itemId,
                        invoiceId,
                        points,
                        quantity,
                        siteName,
                        clientName,
                        totalPoints,
                    } = doc.data();
                    pointEntries.push({
                        id: doc.id,
                        contractorId,
                        date,
                        itemId,
                        invoiceId,
                        quantity,
                        siteName,
                        clientName,
                        points,
                        totalPoints,
                    });
                });
                return pointEntries;
            })
        );
    }

    getPointAllEntries(): Observable<PointEntries[]> {
        const pointEntriesQuery = query(
            collection(this.db, this.COLLECTION_NAME)
        );
        return from(
            getDocsFromServer(pointEntriesQuery).then((querySnapshot) => {
                const pointEntries: PointEntries[] = [];
                querySnapshot.forEach((doc) => {
                    const {
                        date,
                        itemId,
                        invoiceId,
                        points,
                        quantity,
                        siteName,
                        clientName,
                        totalPoints,
                        contractorId,
                    } = doc.data();
                    pointEntries.push({
                        id: doc.id,
                        contractorId,
                        date,
                        itemId,
                        invoiceId,
                        quantity,
                        siteName,
                        clientName,
                        points,
                        totalPoints,
                    });
                });
                return pointEntries;
            })
        );
    }

    addPointEntry(input: PointEntriesVariables): Observable<PointEntries> {
        const user = this.auth.currentUser;
        return from(
            addDoc(collection(this.db, this.COLLECTION_NAME), {
                createdBy: user.uid,
                ...input,
            }).then((docRef) => {
                return { id: docRef.id, ...input };
            })
        );
    }

    updatePointEntry(
        id: string,
        input: PointEntriesVariables
    ): Observable<PointEntries> {
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        return from(
            updateDoc(docRef, { ...input }).then((_) => {
                return {
                    id,
                    ...input,
                };
            })
        );
    }

    deletePointEntry(id: string): Observable<boolean> {
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        return from(deleteDoc(docRef).then(() => true));
    }
}
