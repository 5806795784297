import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState, State } from './authentication.model';

export const selectAuthenticationState = createFeatureSelector<State, AuthenticationState>('authentication');

export const selectIsAuthenticating = createSelector(selectAuthenticationState, (state) => state.isAuthenticating);

export const selectIsLoggedIn = createSelector(selectAuthenticationState, (state) => state.isLoggedIn);

export const selectAuthToken = createSelector(selectAuthenticationState, (state) => state.token);

export const selectRedirectUrl = createSelector(selectAuthenticationState, (state) => state.redirectUrl);

export const selectAuthError = createSelector(selectAuthenticationState, (state) => state.authError);
