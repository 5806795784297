import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { getAuth } from 'firebase/auth';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
    private auth = getAuth();

    constructor(private readonly router: Router, private readonly authenticationService: AuthenticationService, private readonly usersService: UserService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // TODO: update the user authentication logic
        return new Promise((resolve, reject) => {
            this.auth.onAuthStateChanged(
                (user) => {
                    if (user) {
                        user.getIdToken().then((token) => {
                            this.usersService.getUserDetailsByUserId(user.uid);
                            this.authenticationService.setUserAuthToken(token);
                            resolve(true);
                        });
                    } else {
                        this.router.navigate(['./auth']);
                        resolve(false);
                    }
                },
                (error) => {
                    this.authenticationService.setAuthenticationError(error.message);
                    reject();
                }
            );
        });
    }
}
