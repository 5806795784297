import { Injectable } from '@angular/core';
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocsFromServer,
    getFirestore,
    orderBy,
    query,
    updateDoc,
    where,
    writeBatch,
} from 'firebase/firestore';
import {
    deleteObject,
    getDownloadURL,
    getStorage,
    ref,
    uploadBytes,
    UploadResult,
} from 'firebase/storage';
import { from, Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserRoles } from '../authentication/store/authentication.model';
import {
    FilesAndFolders,
    FilesAndFoldersVariables,
    FileTypes,
} from './file-manager.model';
const { v4: uuidv4 } = require('uuid');

@Injectable({
    providedIn: 'root',
})
export class FileManagerDataService {
    private db = getFirestore();
    private storage = getStorage();
    private COLLECTION_NAME = 'filesAndFolders';

    constructor(
        private readonly authenticationService: AuthenticationService
    ) {}

    getFilesAndFolders(parentFolderId: string): Observable<FilesAndFolders[]> {
        const [role, uid] = this.authenticationService.getUserUidAndRole();
        let filesAndFoldersQuery = query(
            collection(this.db, this.COLLECTION_NAME)
        );
        if (role === UserRoles.ADMIN || role === UserRoles.SALESADMIN) {
            filesAndFoldersQuery = query(
                collection(this.db, this.COLLECTION_NAME),
                where('folderId', '==', parentFolderId),
                orderBy('createdAt', 'asc'),
                orderBy('name', 'asc')
            );
        } else {
            filesAndFoldersQuery = query(
                collection(this.db, this.COLLECTION_NAME),
                where('createdBy', '==', uid),
                where('folderId', '==', parentFolderId),
                orderBy('createdAt', 'asc'),
                orderBy('name', 'asc')
            );
        }
        return from(
            getDocsFromServer(filesAndFoldersQuery).then((querySnapshot) => {
                const filesAndFolders: FilesAndFolders[] = [];
                querySnapshot.forEach((doc) => {
                    const {
                        name,
                        createdBy,
                        createdAt,
                        modifiedAt,
                        size,
                        type,
                        downloadLink,
                    } = doc.data();
                    filesAndFolders.push({
                        id: doc.id,
                        folderId: parentFolderId,
                        name,
                        createdBy,
                        createdAt,
                        modifiedAt,
                        size,
                        type,
                        downloadLink,
                    });
                });
                return filesAndFolders;
            })
        );
    }

    addFolder(
        folderName: string,
        parentFolderId: string
    ): Observable<FilesAndFolders> {
        const [_, uid] = this.authenticationService.getUserUidAndRole();
        const input: FilesAndFoldersVariables = {
            folderId: parentFolderId,
            name: folderName,
            createdBy: uid,
            createdAt: new Date().toISOString(),
            modifiedAt: new Date().toISOString(),
            size: 0,
            type: FileTypes.Folder,
            downloadLink: '',
        };
        return from(
            addDoc(collection(this.db, this.COLLECTION_NAME), input).then(
                (docRef) => ({
                    id: docRef.id,
                    ...input,
                })
            )
        );
    }

    addFiles(
        files: File[],
        parentFolderId: string,
        fullPath: string
    ): Observable<FilesAndFolders[]> {
        const [_, uid] = this.authenticationService.getUserUidAndRole();
        const finalFiles: FilesAndFolders[] = [];
        const filesUploadBytesPromises = [];
        const batch = writeBatch(this.db);
        files.forEach((file) => {
            const path = `fileManager/${
                fullPath ? fullPath + file.name : file.name
            }`;
            let fileType: FileTypes = null;
            switch (file.type) {
                case 'image/jpeg':
                    fileType = FileTypes.JPG;
                    break;
                case 'image/png':
                    fileType = FileTypes.PNG;
                    break;
                case 'application/pdf':
                    fileType = FileTypes.PDF;
                    break;
            }
            const input = {
                id: uuidv4(),
                folderId: parentFolderId,
                name: file.name,
                createdBy: uid,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
                size: file.size,
                type: fileType,
                downloadLink: '',
            };
            filesUploadBytesPromises.push(
                uploadBytes(ref(this.storage, path), file)
            );
            finalFiles.push(input);
        });
        return from(
            Promise.all(filesUploadBytesPromises).then(
                (uploadBytesSnapshot: UploadResult[]) => {
                    const downloadUrlPromises = uploadBytesSnapshot.map(
                        (snapshot) => getDownloadURL(snapshot.ref)
                    );
                    return Promise.all(downloadUrlPromises).then(
                        (downloadUrls) => {
                            finalFiles.forEach((file, index) => {
                                file.downloadLink = downloadUrls[index];
                                batch.set(
                                    doc(this.db, this.COLLECTION_NAME, file.id),
                                    file
                                );
                            });
                            return batch.commit().then(() => finalFiles);
                        }
                    );
                }
            )
        );
    }

    updateFolderName(id: string, updatedName: string): Observable<boolean> {
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        return from(updateDoc(docRef, { name: updatedName }).then((_) => true));
    }

    deleteFile(id: string, fullPath: string): Observable<boolean> {
        return from(
            Promise.all([
                deleteDoc(doc(this.db, this.COLLECTION_NAME, id)),
                deleteObject(ref(this.storage, fullPath)),
            ]).then((_) => true)
        );
    }
}
