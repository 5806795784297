import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Contractor } from '../contractors/contractors.model';
import { ContractorsService } from '../contractors/contractors.service';
import { Item } from '../inventory/inventory.model';
import { PointEntriesDataService } from './point-entries.data.service';
import { PointEntries, PointEntriesVariables } from './point-entries.model';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class PointEntriesService {
    private pointEntries: Map<string, PointEntries[]> = new Map();
    private allPointEntries: BehaviorSubject<Map<string, PointEntries[]>> =
        new BehaviorSubject(this.pointEntries);

    allPointEntries$ = this.allPointEntries.asObservable();

    constructor(
        private readonly pointEntriesDataService: PointEntriesDataService,
        private readonly contractorsService: ContractorsService
    ) {}

    getPointEntriesByContractor(contractorId: string): void {
        if (!this.pointEntries.has(contractorId)) {
            this.pointEntriesDataService
                .getPointEntries(contractorId)
                .pipe(first())
                .subscribe((entries) => {
                    this.pointEntries.set(
                        contractorId,
                        _.sortBy(entries, (x) => x.date)
                    );
                    this.allPointEntries.next(this.pointEntries);
                });
        }
    }

    getAllPointEntries(): void {
        this.pointEntriesDataService
            .getPointAllEntries()
            .pipe(first())
            .subscribe((entries) => {
                entries.forEach((entry) => {
                    const existingEntries =
                        this.pointEntries.get(entry.contractorId) || [];
                    existingEntries.push(entry);
                    this.pointEntries.set(
                        entry.contractorId,
                        _.sortBy(existingEntries, (x) => x.date)
                    );
                });
            });
    }

    addPointEntry(
        input: PointEntriesVariables,
        item: Item,
        contractor: Contractor
    ): void {
        this.pointEntriesDataService
            .addPointEntry(input)
            .pipe(first())
            .subscribe((entry) => {
                this.contractorsService.sendContractorSms(
                    contractor.mobileNo,
                    item.name,
                    input.quantity,
                    input.date
                );
                const existingEntries =
                    this.pointEntries.get(input.contractorId) || [];
                existingEntries.push(entry);
                this.pointEntries.set(
                    input.contractorId,
                    _.sortBy(existingEntries, (x) => x.date)
                );
                this.contractorsService.updateContractorPoint(
                    input.contractorId,
                    input.totalPoints
                );
                this.allPointEntries.next(this.pointEntries);
            });
    }

    addPointEntryForInvoice(
        input: PointEntriesVariables,
        contractor: Contractor
    ): void {
        this.pointEntriesDataService
            .addPointEntry(input)
            .pipe(first())
            .subscribe((entry) => {
                this.contractorsService.sendContractorInvoiceSms(
                    contractor.mobileNo,
                    input.invoiceId,
                    input.quantity,
                    input.date,
                    input.clientName
                );
                const existingEntries =
                    this.pointEntries.get(input.contractorId) || [];
                existingEntries.push(entry);
                this.pointEntries.set(
                    input.contractorId,
                    _.sortBy(existingEntries, (x) => x.date)
                );
                this.contractorsService.updateContractorPoint(
                    input.contractorId,
                    input.totalPoints
                );
                this.allPointEntries.next(this.pointEntries);
            });
    }

    updatePointEntry(id: string, input: PointEntriesVariables): void {
        this.pointEntriesDataService
            .updatePointEntry(id, input)
            .pipe(first())
            .subscribe((updatedEntry) => {
                const existingEntries = this.pointEntries.get(
                    input.contractorId
                );
                const existingEntryIndex = existingEntries.findIndex(
                    (entry) => entry.id === id
                );
                const updatedContractorPoint =
                    input.totalPoints -
                    existingEntries[existingEntryIndex].totalPoints;
                this.contractorsService.updateContractorPoint(
                    input.contractorId,
                    updatedContractorPoint
                );
                existingEntries[existingEntryIndex] = updatedEntry;
                this.pointEntries.set(
                    input.contractorId,
                    _.sortBy(existingEntries, (x) => x.date)
                );
                this.allPointEntries.next(this.pointEntries);
            });
    }

    deletePointEntry(id: string, contractorId: string): void {
        this.pointEntriesDataService
            .deletePointEntry(id)
            .pipe(first())
            .subscribe((deleted) => {
                if (deleted) {
                    const existingEntries = this.pointEntries.get(contractorId);
                    const existingEntryIndex = existingEntries.findIndex(
                        (entry) => entry.id === id
                    );
                    this.contractorsService.updateContractorPoint(
                        contractorId,
                        -existingEntries[existingEntryIndex].totalPoints
                    );
                    existingEntries.splice(existingEntryIndex, 1);
                    this.pointEntries.set(
                        contractorId,
                        _.sortBy(existingEntries, (x) => x.date)
                    );
                    this.allPointEntries.next(this.pointEntries);
                }
            });
    }
}
