import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Item, ItemUnit } from 'app/core/inventory/inventory.model';
import { InventoryService } from 'app/core/inventory/inventory.service';

@Component({
    selector: 'app-update-inventory-item',
    templateUrl: './update-inventory-item.component.html',
    styleUrls: ['./update-inventory-item.component.scss'],
})
export class UpdateInventoryItemComponent {
    units: ItemUnit[] = [];
    updateItemForm: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: Item, private readonly inventoryService: InventoryService) {
        this.updateItemForm = new FormGroup({
            name: new FormControl(this.data.name, [Validators.required]),
            unit: new FormControl(this.data.unit, [Validators.required]),
            points: new FormControl(this.data.points, [Validators.required]),
            remarks: new FormControl(this.data.remarks),
        });
        Object.values(ItemUnit).forEach((type) => this.units.push(type));
    }

    updateItem(): void {
        this.updateItemForm.disable();
        const { name, unit, points, remarks } = this.updateItemForm.value;
        this.inventoryService.updateItem(this.data.id, name, unit, +points, remarks);
    }
}
