<div mat-dialog-content class="custom-dialog-content">
    <form class="flex flex-col bg-card shadow rounded overflow-hidden" [formGroup]="addNewUserForm">
        <div class="bg-gray-50 dark:bg-gray-700 p-8 border-b">
            <p class="text-lg font-medium">Create new user</p>
            <p class="text-secondary">Enter the below information for creating a new user.</p>
        </div>
        <div class="flex flex-row p-8 pb-0">
            <mat-form-field class="fuse-mat-dense flex-auto p-2">
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto p-2">
                <mat-label>Username</mat-label>
                <input type="text" matInput formControlName="username" required />
            </mat-form-field>
        </div>

        <div class="flex flex-row p-8 pt-0 pb-0">
            <mat-form-field class="fuse-mat-dense flex-auto p-2">
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto p-2">
                <mat-label>Sales Persons</mat-label>
                <mat-select formControlName="salesPersons" multiple>
                    <mat-option *ngFor="let user of allUsers" [value]="user.uid">{{ user.displayName }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex flex-col p-8 pt-0 pb-2">
            <span class="font-semibold p-2 pb-0">Client</span>
            <div class="flex flex-row" formGroupName="client">
                <mat-checkbox class="flex-auto p-2" formControlName="view" [color]="'primary'"> View </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="create" [color]="'primary'"> Create </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="edit" [color]="'primary'"> Edit </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="delete" [color]="'primary'"> Delete </mat-checkbox>
            </div>
        </div>

        <div class="flex flex-col p-8 pt-0 pb-2">
            <span class="font-semibold p-2 pb-0">Invoice</span>
            <div class="flex flex-row" formGroupName="invoice">
                <mat-checkbox class="flex-auto p-2" formControlName="view" [color]="'primary'"> View </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="create" [color]="'primary'"> Create </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="edit" [color]="'primary'"> Edit </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="delete" [color]="'primary'"> Delete </mat-checkbox>
            </div>
        </div>

        <div class="flex flex-col p-8 pt-0 pb-2">
            <span class="font-semibold p-2 pb-0">Contractor</span>
            <div class="flex flex-row" formGroupName="contractor">
                <mat-checkbox class="flex-auto p-2" formControlName="view" [color]="'primary'"> View </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="create" [color]="'primary'"> Create </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="edit" [color]="'primary'"> Edit </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="delete" [color]="'primary'"> Delete </mat-checkbox>
            </div>
        </div>

        <div class="flex flex-col p-8 pt-0 pb-2">
            <span class="font-semibold p-2 pb-0">Inventory</span>
            <div class="flex flex-row" formGroupName="inventory">
                <mat-checkbox class="flex-auto p-2" formControlName="view" [color]="'primary'"> View </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="create" [color]="'primary'"> Create </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="edit" [color]="'primary'"> Edit </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="delete" [color]="'primary'"> Delete </mat-checkbox>
            </div>
        </div>

        <div class="flex flex-col p-8 pt-0 pb-2">
            <span class="font-semibold p-2 pb-0">User Management</span>
            <div class="flex flex-row" formGroupName="userManagement">
                <mat-checkbox class="flex-auto p-2" formControlName="view" [color]="'primary'"> View </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="create" [color]="'primary'"> Create </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="edit" [color]="'primary'"> Edit </mat-checkbox>
                <mat-checkbox class="flex-auto p-2" formControlName="delete" [color]="'primary'"> Delete </mat-checkbox>
            </div>
        </div>

        <div class="flex flex-col p-8 pt-0 pb-2">
            <span class="font-semibold p-2 pb-0">File Manager</span>
            <div class="flex flex-row" formGroupName="fileManager">
                <mat-checkbox class="flex-auto p-2" formControlName="view" [color]="'primary'"> View/Upload </mat-checkbox>
            </div>
        </div>

        <div class="flex flex-col p-8 pt-0 pb-2">
            <span class="font-semibold p-2 pb-0">Backup</span>
            <div class="flex flex-row" formGroupName="backup">
                <mat-checkbox class="flex-auto p-2" formControlName="view" [color]="'primary'"> View/Create </mat-checkbox>
            </div>
        </div>

        <div class="flex flex-col p-8 pt-0">
            <span class="font-semibold p-2 pb-0">Authorize Invoice</span>
            <div class="flex flex-row" formGroupName="authorize">
                <mat-checkbox class="flex-auto p-2" formControlName="view" [color]="'primary'"> View/Authorize </mat-checkbox>
            </div>
        </div>

        <div class="flex items-center justify-end border-t px-8 py-5 bg-gray-50 dark:bg-gray-700">
            <button mat-button mat-dialog-close>Cancel</button>
            <button
                class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'"
                [disabled]="!addNewUserForm.valid"
                (click)="addClient()"
            >
                <span *ngIf="!addNewUserForm.disabled"> Save </span>
                <mat-progress-spinner
                    *ngIf="addNewUserForm.disabled"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
            </button>
        </div>
    </form>
</div>
