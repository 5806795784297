import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class HttpClientService {
    private accessToken: string;

    constructor(private readonly http: HttpClient, private readonly authenticationService: AuthenticationService) {
        this.authenticationService.authToken$.subscribe((token) => {
            this.accessToken = token;
        });
    }

    createAuthorizationHeader(): HttpHeaders {
        return new HttpHeaders({
            Authorization: `Bearer ${this.accessToken}`,
        });
    }

    get(url: string): Observable<any> {
        const headers = this.createAuthorizationHeader();
        return this.http
            .get(url, {
                headers,
            })
            .pipe(
                catchError((e) => {
                    if (e.status === 401) {
                        this.authenticationService.logout();
                    }
                    return throwError(e);
                })
            );
    }

    post(url: string, data: any): Observable<any> {
        const headers = this.createAuthorizationHeader();
        return this.http
            .post(url, data, {
                headers,
            })
            .pipe(
                catchError((e) => {
                    if (e.status === 401) {
                        this.authenticationService.logout();
                    }
                    return throwError(e);
                })
            );
    }

    put(url: string, data: any): Observable<any> {
        const headers = this.createAuthorizationHeader();
        return this.http
            .put(url, data, {
                headers,
            })
            .pipe(
                catchError((e) => {
                    if (e.status === 401) {
                        this.authenticationService.logout();
                    }
                    return throwError(e);
                })
            );
    }

    delete(url: string): Observable<any> {
        const headers = this.createAuthorizationHeader();
        return this.http
            .delete(url, {
                headers,
            })
            .pipe(
                catchError((e) => {
                    if (e.status === 401) {
                        this.authenticationService.logout();
                    }
                    return throwError(e);
                })
            );
    }
}
