import { Injectable } from '@angular/core';
import {
    browserSessionPersistence,
    getAuth,
    setPersistence,
    signInWithEmailAndPassword,
    UserCredential,
} from 'firebase/auth';
import { from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationDataService {
    auth = getAuth();

    constructor() {}

    loginWithEmailAndPassword(email: string, password: string): Observable<UserCredential> {
        return from(
            setPersistence(this.auth, browserSessionPersistence).then(() =>
                signInWithEmailAndPassword(this.auth, email, password).then((user) => user)
            )
        );
    }

    fetchUserAuthToken(): Observable<string> {
        const user = this.auth.currentUser;
        return from(user.getIdToken().then((token) => token));
    }
}
