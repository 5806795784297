import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { InventoryDataService } from './inventory.data.service';
import { Item, ItemUnit, ItemVariable, StockEntry, StockEntryType } from './inventory.model';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class InventoryService {
    private items: Item[] = [];
    private allItems: BehaviorSubject<Item[]> = new BehaviorSubject(this.items);
    private isItemsLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);

    allItems$ = this.allItems.asObservable();
    isItemsLoading$ = this.isItemsLoading.asObservable();

    constructor(private readonly inventoryDataService: InventoryDataService) {}

    getAllInventoryItems(): void {
        if (!this.items.length) {
            this.isItemsLoading.next(true);
            this.inventoryDataService
                .getAllItems()
                .pipe(first())
                .subscribe((items) => {
                    this.items = _.sortBy(items, x => x.name.toLowerCase());
                    this.allItems.next(this.items);
                    this.isItemsLoading.next(false);
                });
        }
    }

    addNewInventoryItem(input: ItemVariable): void {
        this.inventoryDataService
            .addNewItem(input)
            .pipe(first())
            .subscribe((item) => {
                this.items.push(item);
                this.items = _.sortBy(this.items, x => x.name.toLowerCase());
                this.allItems.next(this.items);
            });
    }

    updateItem(id: string, name: string, unit: ItemUnit, points: number, remarks: string): void {
        this.inventoryDataService
            .updateItem(id, name, unit, points, remarks)
            .pipe(first())
            .subscribe((status) => {
                if (status) {
                    const existingItemIndex = this.items.findIndex((item) => item.id === id);
                    this.items[existingItemIndex].name = name;
                    this.items[existingItemIndex].unit = unit;
                    this.items[existingItemIndex].points = points;
                    this.items[existingItemIndex].remarks = remarks;
                    this.items = _.sortBy(this.items, x => x.name.toLowerCase());
                    this.allItems.next(this.items);
                }
            });
    }

    updateItemQuantity(id: string, quantity: number): void {
        this.inventoryDataService
            .updateItemQuantity(id, quantity)
            .pipe(first())
            .subscribe((updatedItem) => {
                const existingItemIndex = this.items.findIndex((item) => item.id === id);
                this.items[existingItemIndex] = updatedItem;
                this.items = _.sortBy(this.items, x => x.name.toLowerCase());
                this.allItems.next(this.items);
            });
    }

    deleteItem(id: string): void {
        this.inventoryDataService
            .deleteItem(id)
            .pipe(first())
            .subscribe((status) => {
                if (status) {
                    const existingItemIndex = this.items.findIndex((item) => item.id === id);
                    if (existingItemIndex > -1) {
                        this.items.splice(existingItemIndex, 1);
                        this.items = _.sortBy(this.items, x => x.name.toLowerCase());
                        this.allItems.next(this.items);
                    }
                }
            });
    }

    addInventoryStockEntry(input: StockEntry): void {
        this.inventoryDataService
            .addStockEntry(input)
            .pipe(first())
            .subscribe((status) => {
                if (status) {
                    const existingItem = this.items.find((item) => item.id === input.itemId);
                    const currentQuantity = existingItem.quantity;
                    const newQuantity =
                        input.type === StockEntryType.Inward
                            ? currentQuantity + input.quantity
                            : currentQuantity - input.quantity;
                    this.updateItemQuantity(existingItem.id, newQuantity - currentQuantity);
                }
            });
    }
}
