export interface FilesAndFolders extends FilesAndFoldersVariables {
    id: string;
}

export interface FilesAndFoldersVariables {
    folderId: string;
    name: string;
    createdBy: string;
    createdAt: string;
    modifiedAt: string;
    size: number;
    type: FileTypes;
    downloadLink: string;
}

export enum FileTypes {
    Folder = 'folder',
    JPG = 'JPG',
    PNG = 'PNG',
    PDF = 'PDF',
}
