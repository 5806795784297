import { AppState } from 'app/core/core.state';
import { UserDetails } from 'app/core/user/store/user.model';

export enum UserRoles {
    ADMIN = 'ADMIN',
    SALESPERSON = 'SALESPERSON',
    SALESADMIN = 'SALESADMIN',
}

export interface AuthenticationState {
    isAuthenticating?: boolean;
    isLoggedIn: boolean;
    token: string;
    refreshToken: string;
    authError?: string;
    redirectUrl?: string;
    passwordUpdated?: boolean;
}

export interface State extends AppState {
    authentication: AuthenticationState;
}
