import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { environment } from 'environments/environment';
import * as firebase from 'firebase/app';
import { reducers, metaReducers } from './core.state';
import { CustomSerializer } from './router/custom-serializer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthenticationModule } from './authentication/authentication.module';
import { EffectsModule } from '@ngrx/effects';
import { UserModule } from './user/user.module';

firebase.initializeApp(environment.firebaseConfig);
@NgModule({
    imports: [
        IconsModule,
        TranslocoCoreModule,
        CommonModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot(),
        environment.production ? [] : StoreDevtoolsModule.instrument({ name: environment.appName }),
        AuthenticationModule,
        UserModule
    ],
    providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }],
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
