<div mat-dialog-content class="custom-dialog-content">
    <form class="flex flex-col bg-card shadow rounded overflow-hidden" [formGroup]="updateContractorForm">
        <div class="bg-gray-50 dark:bg-gray-700 p-8 border-b">
            <p class="text-lg font-medium">Update contractor</p>
            <p class="text-secondary">Enter the below information for updating contractor.</p>
        </div>
        <div class="flex flex-col p-8">
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Mobile No.</mat-label>
                <input matInput formControlName="mobileNo" required />
            </mat-form-field>
        </div>

        <div class="flex items-center justify-end border-t px-8 py-5 bg-gray-50 dark:bg-gray-700">
            <button mat-button mat-dialog-close>Cancel</button>
            <button
                class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'"
                [disabled]="!updateContractorForm.valid"
                (click)="updateContractor()"
            >
                <span *ngIf="!updateContractorForm.disabled"> Update </span>
                <mat-progress-spinner
                    *ngIf="updateContractorForm.disabled"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
            </button>
        </div>
    </form>
</div>
