import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';
import { combineLatest, from, Observable } from 'rxjs';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { UserRoles } from '../authentication/store/authentication.model';
import { selectAuthToken } from '../authentication/store/authentication.selector';
import { AppState } from '../core.state';
import { HttpClientService } from '../http/http-client.service';
import { UserDetails, UserFormData } from './store/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    db = getFirestore();

    constructor(private readonly store$: Store<AppState>, private readonly httpClientService: HttpClientService) {}

    getUserDetailsByUserId(userId: string): Observable<UserDetails> {
        const docRef = doc(this.db, 'users', userId);
        return from(
            getDoc(docRef).then((docSnapshot) => {
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    const userDetails: UserDetails = {
                        uid: docSnapshot.id,
                        displayName: data.displayName,
                        email: data.email,
                        role: data.role,
                        permissions: data.permissions,
                        salesPersons: data.salesPersons,
                    };
                    return userDetails;
                } else {
                    throw new Error('User details not found!');
                }
            })
        );
    }

    getAllUsersDetails(): Observable<UserDetails[]> {
        return from(
            getDocs(collection(this.db, 'users')).then((querySnapshot) => {
                const users: UserDetails[] = [];
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    users.push({
                        uid: doc.id,
                        displayName: data.name,
                        email: data.email,
                        role: data.role,
                        permissions: data.permissions,
                        salesPersons: data.salesPersons,
                    });
                });
                return users;
            })
        );
    }

    addNewUser(input: UserFormData): Observable<UserDetails> {
        const url = environment.serviceUrl + environment.api.createUser;
        return this.httpClientService.post(url, input);
    }
}
