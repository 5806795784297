import { MetaReducer, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { environment } from 'environments/environment';
import { clearState } from './meta-reducers/clear-state.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { initState } from './meta-reducers/init-state.reducer';
import { RouterStateUrl } from './router/router.state';
import { debug } from './meta-reducers/debug.reducer';

export const metaReducers: MetaReducer<AppState>[] = [initState, clearState];

if (!environment.production) {
    metaReducers.unshift(debug);
}

export const reducers: ActionReducerMap<AppState> = {
    router: routerReducer,
};

export const selectRouterState = createFeatureSelector<AppState, RouterReducerState<RouterStateUrl>>('router');

export interface AppState {
    router: RouterReducerState<RouterStateUrl>;
}
