import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvoiceReceiveType } from 'app/core/invoices/invoices.model';
import { InvoicesService } from 'app/core/invoices/invoices.service';
import { ReplaySubject } from 'rxjs';

interface IData {
    invoiceId: string;
    clientName: string;
    invoiceNo: string;
}

@Component({
    selector: 'app-invoice-receive',
    templateUrl: './invoice-receive.component.html',
    styleUrls: ['./invoice-receive.component.scss'],
})
export class InvoiceReceiveComponent implements OnInit {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    types: string[] = [InvoiceReceiveType.Cash, InvoiceReceiveType.Cheque, InvoiceReceiveType.Online];
    addInvoiceAmount: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: IData, private readonly invoicesService: InvoicesService) {
        this.addInvoiceAmount = new FormGroup({
            amount: new FormControl(0, [Validators.required]),
            date: new FormControl(new Date(), [Validators.required]),
            type: new FormControl(InvoiceReceiveType.Cash, [Validators.required]),
            remarks: new FormControl(''),
            chequeNo: new FormControl(''),
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    addClient(): void {
        this.addInvoiceAmount.disable();
        const { amount, date, type, remarks, chequeNo } = this.addInvoiceAmount.value;
        this.invoicesService.addInvoiceReceivedAmount(this.data.invoiceId, {
            amount: Number.parseFloat(amount),
            date: new Date(date).toISOString(),
            type,
            remarks: remarks || '',
            chequeNo: chequeNo || '',
        });
    }
}
