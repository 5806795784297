import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { AppStoreFeatureName } from '../global/store/global.model';
import { usersReducer } from './store/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/user.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(AppStoreFeatureName.Users, usersReducer),
        EffectsModule.forFeature([UserEffects]),
    ],
})
export class UserModule {}
