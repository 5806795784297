export interface Item {
    id: string;
    name: string;
    unit: ItemUnit;
    quantity: number;
    points: number;
    remarks: string;
    deleted?: boolean;
}

export enum ItemUnit {
    PCS = 'PCS',
    BAG = 'BAG',
    KGS = 'KGS',
}

export enum StockEntryType {
    Inward = 'INWARD',
    Outward = 'OUTWARD',
}

export interface StockEntry {
    itemId: string;
    quantity: number;
    dateTime: string;
    type: StockEntryType;
    remarks: string;
}

export interface ItemVariable {
    name: string;
    unit: ItemUnit;
    quantity: number;
    points: number;
    remarks: string;
}
