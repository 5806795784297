import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientsService } from 'app/core/clients/clients.service';
import { ItemUnit, StockEntryType } from 'app/core/inventory/inventory.model';
import { InventoryService } from 'app/core/inventory/inventory.service';
import { UserDetails } from 'app/core/user/store/user.model';
import { UserService } from 'app/core/user/user.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-create-inventory-item',
    templateUrl: './create-inventory-item.component.html',
    styleUrls: ['./create-inventory-item.component.scss'],
})
export class CreateInventoryItemComponent {
    units: ItemUnit[] = [];
    addNewItemForm: FormGroup;

    constructor(private readonly inventoryService: InventoryService) {
        this.addNewItemForm = new FormGroup({
            name: new FormControl('', [Validators.required]),
            quantity: new FormControl(0, [Validators.required]),
            points: new FormControl(0, [Validators.required]),
            unit: new FormControl('', [Validators.required]),
            remarks: new FormControl(''),
        });
        Object.values(ItemUnit).forEach((type) => this.units.push(type));
    }

    addNewItem(): void {
        this.addNewItemForm.disable();
        const { name, quantity, points, unit, remarks } = this.addNewItemForm.value;
        this.inventoryService.addNewInventoryItem({ name, quantity: +quantity, points: +points, unit, remarks });
    }
}
