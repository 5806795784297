<div mat-dialog-content class="custom-dialog-content">
    <form
        class="flex flex-col bg-card shadow rounded overflow-hidden"
        [formGroup]="addNewInvoiceForm"
    >
        <div class="bg-gray-50 dark:bg-gray-700 p-8 border-b">
            <p class="text-lg font-medium">Create new invoice</p>
            <p class="text-secondary">
                Enter the below information for creating a new invoice.
            </p>
        </div>
        <div class="flex flex-row p-8 pb-0">
            <mat-form-field class="fuse-mat-dense flex-auto p-2">
                <mat-label>Invoice No</mat-label>
                <input matInput formControlName="invoiceNo" required />
            </mat-form-field>
            <input
                type="file"
                id="fileInput"
                accept="image/*"
                #file
                (change)="handleFileInput($event.target.files)"
                hidden
            />
            <button
                class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'"
                style="align-self: center"
                (click)="file.click()"
            >
                {{ uploadBtnText }}
            </button>
            <button
                class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'"
                style="align-self: center"
                (click)="openCreateClientDialog()"
            >
                Add new client
            </button>
        </div>
        <div class="flex flex-row p-8 pt-0 pb-0 w-1/2">
            <mat-form-field class="fuse-mat-dense flex-auto p-2 w-1/2">
                <mat-label>Invoice Type</mat-label>
                <mat-select formControlName="invoiceType" required>
                    <mat-option
                        *ngFor="let type of invoiceType; let i = index"
                        [value]="type"
                        >{{ type }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex flex-row p-8 pt-0 pb-0">
            <mat-form-field class="fuse-mat-dense flex-auto p-2">
                <mat-label>Date</mat-label>
                <input type="date" matInput formControlName="date" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto p-2">
                <mat-label>Due Dates</mat-label>
                <mat-select formControlName="dueDate" required>
                    <mat-option
                        *ngFor="let day of days; let i = index"
                        [value]="i + 1"
                        >{{ i + 1 }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex flex-row p-8 pt-0 pb-0">
            <mat-form-field class="fuse-mat-dense flex-auto p-2">
                <mat-label>Client</mat-label>
                <mat-select formControlName="clientId" required>
                    <mat-option
                        *ngFor="
                            let client of allClients$ | async;
                            let i = index
                        "
                        [value]="client.id"
                        >{{ client.name }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto p-2">
                <mat-label>Remarks</mat-label>
                <input matInput formControlName="remarks" />
            </mat-form-field>
        </div>
        <ng-container
            *ngFor="let group of itemsInvoiceArray.controls; let i = index"
            formArrayName="items"
        >
            <div class="flex flex-row p-8 pt-0 pb-0" [formGroupName]="i">
                <mat-form-field class="fuse-mat-dense flex-auto p-2 pb-0">
                    <mat-select
                        formControlName="itemId"
                        placeholder="Item"
                        required
                    >
                        <mat-option
                            *ngFor="
                                let item of allItems$ | async;
                                let i = index
                            "
                            [value]="item.id"
                            >{{ item.name }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="fuse-mat-dense flex-auto p-2 pb-0">
                    <input
                        matInput
                        formControlName="quantity"
                        placeholder="Quantity"
                        (input)="
                            countInvoicePerItemTotalFromQuantity(
                                $event.target.value,
                                i
                            )
                        "
                        required
                    />
                </mat-form-field>
                <mat-form-field class="fuse-mat-dense flex-auto p-2 pb-0">
                    <input
                        matInput
                        formControlName="price"
                        placeholder="Price"
                        (input)="
                            countInvoicePerItemTotalFromPrice(
                                $event.target.value,
                                i
                            )
                        "
                        required
                    />
                </mat-form-field>
                <mat-form-field class="fuse-mat-dense flex-auto p-2 pb-0">
                    <input
                        matInput
                        formControlName="totalPrice"
                        placeholder="Total"
                        required
                    />
                </mat-form-field>
                <button
                    mat-icon-button
                    class="p-2 mt-2"
                    (click)="removeItemInvoice(i)"
                >
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'mat_outline:delete_outline'"
                    ></mat-icon>
                </button>
            </div>
        </ng-container>
        <div class="flex flex-row p-8 pt-0 items-center">
            <div class="fuse-mat-dense flex-auto p-2">
                <b>Total Quantity</b>: {{ totalQuantityAndAmount.quantity }}
            </div>
            <div class="fuse-mat-dense flex-auto p-2">
                <b>Total Amount</b>: {{ totalQuantityAndAmount.amount }}
            </div>
            <div class="fuse-mat-dense flex-auto p-2">
                <button
                    mat-flat-button
                    [color]="'accent'"
                    style="float: right"
                    (click)="addMoreItem()"
                >
                    Add more item
                </button>
            </div>
        </div>

        <div
            class="flex items-center justify-end border-t px-8 py-5 bg-gray-50 dark:bg-gray-700"
        >
            <button mat-button mat-dialog-close>Cancel</button>
            <button
                class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'"
                [disabled]="!addNewInvoiceForm.valid"
                (click)="addInvoice()"
            >
                <span *ngIf="!addNewInvoiceForm.disabled"> Save </span>
                <mat-progress-spinner
                    *ngIf="addNewInvoiceForm.disabled"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
            </button>
        </div>
    </form>
</div>
