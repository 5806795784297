import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ItemUnit, StockEntryType } from 'app/core/inventory/inventory.model';
import { InventoryService } from 'app/core/inventory/inventory.service';

@Component({
    selector: 'app-create-inventory-stock',
    templateUrl: './create-inventory-stock.component.html',
    styleUrls: ['./create-inventory-stock.component.scss'],
})
export class CreateInventoryStockComponent {
    types: StockEntryType[] = [];
    allItems$ = this.inventoryService.allItems$;
    addNewStockForm: FormGroup;

    constructor(private readonly inventoryService: InventoryService) {
        this.addNewStockForm = new FormGroup({
            itemId: new FormControl('', [Validators.required]),
            quantity: new FormControl(0, [Validators.required]),
            type: new FormControl('', [Validators.required]),
            remarks: new FormControl(''),
        });
        Object.values(StockEntryType).forEach((type) => this.types.push(type));
    }

    addNewStock(): void {
        this.addNewStockForm.disable();
        const { itemId, quantity, type, remarks } = this.addNewStockForm.value;
        this.inventoryService.addInventoryStockEntry({
            itemId,
            quantity: +quantity,
            type,
            remarks,
            dateTime: new Date().toISOString(),
        });
    }
}
