import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';
import { ContractorsDataService } from './contractors.data.service';
import { Contractor } from './contractors.model';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ContractorsService {
    private contractors: Contractor[] = [];
    private allContractors: BehaviorSubject<Contractor[]> = new BehaviorSubject(this.contractors);
    private isContractorsLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);

    allContractors$ = this.allContractors.asObservable();
    isContractorsLoading$ = this.isContractorsLoading.asObservable();

    constructor(private readonly contractorsDataService: ContractorsDataService) {}

    getAllContractors(): void {
        if (!this.contractors.length) {
            this.isContractorsLoading.next(true);
            this.contractorsDataService
                .getContractors()
                .pipe(first())
                .subscribe((contractors) => {
                    this.contractors = _.sortBy(contractors, (x) => x.name.toLowerCase());
                    this.allContractors.next(this.contractors);
                    this.isContractorsLoading.next(false);
                });
        }
    }

    getContractorById(contractorId: string): Observable<Contractor> {
        return of(this.contractors.find((contractor) => contractor.id === contractorId));
    }

    addContractor(name: string, mobileNo: string, points: number): void {
        this.contractorsDataService
            .addContractor(name, mobileNo, points)
            .pipe(first())
            .subscribe((contractor) => {
                this.contractors.push(contractor);
                this.contractors = _.sortBy(this.contractors, (x) => x.name.toLowerCase());
                this.allContractors.next(this.contractors);
            });
    }

    updateContractor(id: string, name: string, mobileNo: string, points: number): void {
        this.contractorsDataService
            .updateContractor(id, name, mobileNo, points)
            .pipe(first())
            .subscribe((updateContractor) => {
                const existingContractorIndex = this.contractors.findIndex((contractor) => contractor.id === id);
                this.contractors[existingContractorIndex] = updateContractor;
                this.contractors = _.sortBy(this.contractors, (x) => x.name.toLowerCase());
                this.allContractors.next(this.contractors);
            });
    }

    deleteContractor(id: string): void {
        this.contractorsDataService
            .deleteContractor(id)
            .pipe(first())
            .subscribe((deleted) => {
                if (deleted) {
                    const existingContractorIndex = this.contractors.findIndex((contractor) => contractor.id === id);
                    if (existingContractorIndex > -1) {
                        this.contractors.splice(existingContractorIndex, 1);
                        this.contractors = _.sortBy(this.contractors, (x) => x.name.toLowerCase());
                        this.allContractors.next(this.contractors);
                    }
                }
            });
    }

    updateContractorPoint(contractorId: string, newPoints: number): void {
        const existingContractorIndex = this.contractors.findIndex((contractor) => contractor.id === contractorId);
        const contractor = this.contractors[existingContractorIndex];
        contractor.points += newPoints;
        const { name, mobileNo, points } = contractor;
        this.updateContractor(contractorId, name, mobileNo, points);
    }

    sendContractorSms(mobile: string, item: string, quantity: number, date: string): void {
        this.contractorsDataService
            .sendContractorSms(mobile, item, quantity, date)
            .pipe(first())
            .subscribe((_) => {});
    }

    sendContractorInvoiceSms(
        mobile: string,
        invoiceNo: string,
        quantity: number,
        date: string,
        clientName: string
    ): void {
        this.contractorsDataService
            .sendContractorInvoiceSms(mobile, invoiceNo, quantity, date, clientName)
            .pipe(first())
            .subscribe((_) => {});
    }
}
