import { ActionReducer, INIT, UPDATE } from '@ngrx/store';

import { AppState } from '../core.state';
// import { CookieStorageService } from '../cookie-storage/cookie-storage.service';

export function initState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return (state, action) => {
        const newState = reducer(state, action);
        // if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
        //   const cookieState = CookieStorageService.loadAllState();
        //   return { ...newState, ...cookieState };
        // }
        return newState;
    };
}
