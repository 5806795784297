import { createAction, props } from '@ngrx/store';
import { UserDetails } from './user.model';

export enum UserActionTypes {
    GetUserDetailsByUserIdStart = '[User] Get User Details by User Id Start',
    GetUserDetailsByUserIdSuccess = '[User] Get User Details by User Id Success',
    GetUserDetailsByUserIdFailed = '[User] Get User Details by User Id Failed',
    GetAllUsersDetailsStart = '[User] Get All Users Details Start',
    GetAllUsersDetailsSuccess = '[User] Get All Users Details Success',
    GetAllUsersDetailsFailed = '[User] Get All Users Details Failed',
}

export const ActionGetUserDetailsByUserIdStart = createAction(
    UserActionTypes.GetUserDetailsByUserIdStart,
    props<{ userId: string }>()
);

export const ActionGetUserDetailsByUserIdSuccess = createAction(
    UserActionTypes.GetUserDetailsByUserIdSuccess,
    props<{ userDetails: UserDetails }>()
);

export const ActionGetUserDetailsByUserIdFailed = createAction(
    UserActionTypes.GetUserDetailsByUserIdFailed,
    props<{ userError: string }>()
);

export const ActionGetAllUsersDetailsStart = createAction(UserActionTypes.GetAllUsersDetailsStart);

export const ActionGetAllUsersDetailsSuccess = createAction(
    UserActionTypes.GetAllUsersDetailsSuccess,
    props<{ userDetails: UserDetails[] }>()
);

export const ActionGetAllUsersDetailsFailed = createAction(
    UserActionTypes.GetAllUsersDetailsFailed,
    props<{ userError: string }>()
);
