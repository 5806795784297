import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { AppStoreFeatureName } from '../global/store/global.model';
import { authenticationReducer } from './store/authentication.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from './store/authentication.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(AppStoreFeatureName.Authentication, authenticationReducer),
        EffectsModule.forFeature([AuthenticationEffects]),
    ],
})
export class AuthenticationModule {}
