import { UserDetails, UsersState } from './user.model';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
    ActionGetAllUsersDetailsFailed,
    ActionGetAllUsersDetailsSuccess,
    ActionGetUserDetailsByUserIdFailed,
    ActionGetUserDetailsByUserIdSuccess,
} from './user.actions';

export const userEntityAdapter: EntityAdapter<UserDetails> = createEntityAdapter<UserDetails>();

export const initialState: UsersState = userEntityAdapter.getInitialState({ currentUser: null });

export const reducer = createReducer(
    initialState,
    on(ActionGetUserDetailsByUserIdSuccess, (state, { userDetails }) => ({
        ...state,
        currentUser: userDetails,
    })),
    on(ActionGetUserDetailsByUserIdFailed, (state, { userError }) => ({ ...state, userError })),
    on(ActionGetAllUsersDetailsSuccess, (state, { userDetails }) =>
        userEntityAdapter.addMany(userDetails, { ...state })
    ),
    on(ActionGetAllUsersDetailsFailed, (state, { userError }) => ({ ...state, userError }))
);

export function usersReducer(state: UsersState, action: Action): UsersState {
    return reducer(state, action);
}
