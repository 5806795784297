import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/core/authentication/authentication.service';
import { UserRoles } from 'app/core/authentication/store/authentication.model';
import { ClientsService } from 'app/core/clients/clients.service';
import { UserDetails, UserFormData } from 'app/core/user/store/user.model';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    allUsers: UserDetails[];
    addNewUserForm: FormGroup;

    constructor(
        private readonly userService: UserService,
        private readonly authenticationService: AuthenticationService
    ) {
        this.addNewUserForm = new FormGroup({
            name: new FormControl('', [Validators.required]),
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
            salesPersons: new FormControl([]),
            client: new FormGroup({
                view: new FormControl(true),
                create: new FormControl(false),
                edit: new FormControl(false),
                delete: new FormControl(false),
            }),
            invoice: new FormGroup({
                view: new FormControl(true),
                create: new FormControl(false),
                edit: new FormControl(false),
                delete: new FormControl(false),
            }),
            contractor: new FormGroup({
                view: new FormControl(true),
                create: new FormControl(false),
                edit: new FormControl(false),
                delete: new FormControl(false),
            }),
            inventory: new FormGroup({
                view: new FormControl(true),
                create: new FormControl(false),
                edit: new FormControl(false),
                delete: new FormControl(false),
            }),
            userManagement: new FormGroup({
                view: new FormControl(true),
                create: new FormControl(false),
                edit: new FormControl(false),
                delete: new FormControl(false),
            }),
            fileManager: new FormGroup({
                view: new FormControl(true),
            }),
            backup: new FormGroup({
                view: new FormControl(true),
            }),
            authorize: new FormGroup({
                view: new FormControl(true),
            }),
        });
    }

    ngOnInit(): void {
        this.userService.allUsers$.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
            this.allUsers = users;
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    addClient(): void {
        this.addNewUserForm.disable();
        const [_, uid] = this.authenticationService.getUserUidAndRole();
        const {
            name,
            username,
            password,
            salesPersons,
            client,
            invoice,
            contractor,
            inventory,
            userManagement,
            fileManager,
            backup,
            authorize,
        } = this.addNewUserForm.value;
        const userDetails: UserFormData = {
            name,
            email: `${username}@${environment.domainName}`,
            role: UserRoles.SALESPERSON,
            permissions: {
                client,
                invoice,
                contractor,
                inventory,
                userManagement,
                fileManager,
                backup,
                authorize,
            },
            salesPersons,
            password,
            createdBy: uid,
        };
        this.userService.addNewUser(userDetails);
    }
}
