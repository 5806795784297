<div mat-dialog-content class="custom-dialog-content">
    <form class="flex flex-col bg-card shadow rounded overflow-hidden" [formGroup]="addNewStockForm">
        <div class="bg-gray-50 dark:bg-gray-700 p-8 border-b">
            <p class="text-lg font-medium">Create new stock entry</p>
            <p class="text-secondary">Enter the below information for creating a new stock entry.</p>
        </div>
        <div class="flex flex-col p-8">
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Entry Type</mat-label>
                <mat-select formControlName="type" required>
                    <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Item</mat-label>
                <mat-select formControlName="itemId" required>
                    <mat-option *ngFor="let item of allItems$ | async" [value]="item.id">{{ item.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Stock Quantity</mat-label>
                <input matInput formControlName="quantity" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Remarks</mat-label>
                <input matInput formControlName="remarks" />
            </mat-form-field>
        </div>

        <div class="flex items-center justify-end border-t px-8 py-5 bg-gray-50 dark:bg-gray-700">
            <button mat-button mat-dialog-close>Cancel</button>
            <button
                class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'"
                [disabled]="!addNewStockForm.valid"
                (click)="addNewStock()"
            >
                <span *ngIf="!addNewStockForm.disabled"> Save </span>
                <mat-progress-spinner
                    *ngIf="addNewStockForm.disabled"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
            </button>
        </div>
    </form>
</div>
