import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContractorsService } from 'app/core/contractors/contractors.service';
import { ReplaySubject } from 'rxjs';

@Component({
    selector: 'app-create-contractor',
    templateUrl: './create-contractor.component.html',
    styleUrls: ['./create-contractor.component.scss'],
})
export class CreateContractorComponent {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    addNewContractor: FormGroup;

    constructor(private readonly contractorsService: ContractorsService) {
        this.addNewContractor = new FormGroup({
            name: new FormControl('', [Validators.required]),
            mobileNo: new FormControl('', [Validators.required]),
        });
    }

    addContractor(): void {
        this.addNewContractor.disable();
        const { name, mobileNo } = this.addNewContractor.value;
        this.contractorsService.addContractor(name, mobileNo, 0);
    }
}
