import { createAction, props } from '@ngrx/store';

export enum AuthenticationActionTypes {
    LoginWithUsernameAndPasswordStart = '[Authentication] Login with Username and Password Start',
    LoginWithUsernameAndPasswordSuccess = '[Authentication] Login with Username and Password Success',
    LoginWithUsernameAndPasswordFailed = '[Authentication] Login with Username and Password Failed',
    LogoutStart = '[Authentication] Logout Start',
    LogoutSuccess = '[Authentication] Logout Success',
    LogoutFailed = '[Authentication] Logout Failed',
    SetIsAuthenticating = '[Authentication] Set Is Authenticating',
    SetIsLoggedIn = '[Authentication] Set Is LoggedIn',
    SetToken = '[Authentication] Set Token',
    SetRefreshToken = '[Authentication] Set Refresh Token',
    SetRedirectUrl = '[Authentication] Set Redirect Url',
    SetAuthError = '[Authentication] Set Auth Error',
}

export const ActionLoginWithUsernameAndPasswordStart = createAction(
    AuthenticationActionTypes.LoginWithUsernameAndPasswordStart,
    props<{ username: string; password: string }>()
);

export const ActionLoginWithUsernameAndPasswordSuccess = createAction(
    AuthenticationActionTypes.LoginWithUsernameAndPasswordSuccess
);

export const ActionLoginWithUsernameAndPasswordFailed = createAction(
    AuthenticationActionTypes.LoginWithUsernameAndPasswordFailed,
    props<{ authError: string }>()
);

export const ActionLogoutStart = createAction(AuthenticationActionTypes.LogoutStart);

export const ActionLogoutSuccess = createAction(AuthenticationActionTypes.LogoutSuccess);

export const ActionLogoutFailed = createAction(AuthenticationActionTypes.LogoutFailed, props<{ authError: string }>());

export const ActionSetIsAuthenticating = createAction(
    AuthenticationActionTypes.SetIsAuthenticating,
    props<{ isAuthenticating: boolean }>()
);

export const ActionSetIsLoggedIn = createAction(
    AuthenticationActionTypes.SetIsLoggedIn,
    props<{ isLoggedIn: boolean }>()
);

export const ActionSetToken = createAction(AuthenticationActionTypes.SetToken, props<{ token: string }>());

export const ActionSetRefreshToken = createAction(
    AuthenticationActionTypes.SetRefreshToken,
    props<{ refreshToken: string }>()
);

export const ActionSetRedirectUrl = createAction(
    AuthenticationActionTypes.SetRedirectUrl,
    props<{ redirectUrl: string }>()
);

export const ActionSetAuthError = createAction(AuthenticationActionTypes.SetAuthError, props<{ authError: string }>());
