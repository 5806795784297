export interface Invoice extends InvoiceVariables {
    id: string;
    clientId?: string;
    createdBy: string;
}

export interface InvoiceVariables {
    amount: number;
    authorizedByAdmin: boolean;
    authorizedBySales: boolean;
    client: string;
    date: string;
    dueDate: string;
    fileName: string;
    filePath: string;
    invoiceNo: string;
    invoiceItems: InvoiceItems[];
    paid: boolean;
    remarks: string;
    receivedAmounts: InvoiceReceive[];
    invoiceType: InvoiceType;
}

export interface InvoiceItems {
    itemId: string;
    price: number;
    quantity: number;
    totalPrice: number;
}

export interface InvoiceReceive {
    amount: number;
    date: string;
    type: InvoiceReceiveType;
    remarks?: string;
    chequeNo?: string;
}

export enum InvoiceReceiveType {
    Cash = 'CASH',
    Cheque = 'CHEQUE',
    Online = 'ONLINE',
}

export enum InvoiceType {
    Sales = 'SALES',
    Purchase = 'PURCHASE',
}
