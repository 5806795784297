import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'invoice',
        title: 'Dashboard',
        type: 'basic',
        icon: 'mat_solid:dashboard',
        link: '/home/dashboard',
    },
    {
        id: 'invoice',
        title: 'Manage Invoices',
        type: 'basic',
        icon: 'mat_solid:insert_drive_file',
        link: '/home/invoices/manage',
    },
    {
        id: 'authorize',
        title: 'Authorize Invoices',
        type: 'basic',
        icon: 'mat_solid:insert_drive_file',
        link: '/home/invoices/authorize',
    },
    {
        id: 'client',
        title: 'Clients',
        type: 'basic',
        icon: 'mat_solid:contacts',
        link: '/home/clients',
    },
    {
        id: 'contractor',
        title: 'Contractors',
        type: 'basic',
        icon: 'mat_solid:emoji_people',
        link: '/home/contractors',
    },
    {
        id: 'inventory',
        title: 'Inventory',
        type: 'basic',
        icon: 'mat_solid:inventory',
        link: '/home/inventory',
    },
    {
        id: 'userManagement',
        title: 'User Management',
        type: 'basic',
        icon: 'mat_solid:people',
        link: '/home/users/manage',
    },
    {
        id: 'fileManager',
        title: 'File Manager',
        type: 'basic',
        icon: 'mat_solid:folder',
        link: '/home/files/manage',
    },
    {
        id: 'backup',
        title: 'Backup Database',
        type: 'basic',
        icon: 'mat_solid:storage',
        link: '/home/backup',
    },
];
export const compactNavigation: FuseNavigationItem[] = [];
export const futuristicNavigation: FuseNavigationItem[] = [];
export const horizontalNavigation: FuseNavigationItem[] = [];
