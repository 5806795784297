import { Injectable } from '@angular/core';
import {
    addDoc,
    collection,
    doc,
    getDocFromServer,
    getDocsFromServer,
    getFirestore,
    increment,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { from, Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { Item, ItemUnit, ItemVariable, StockEntry } from './inventory.model';

@Injectable({
    providedIn: 'root',
})
export class InventoryDataService {
    private db = getFirestore();
    private COLLECTION_NAME_INVENTORY = 'inventory';
    private COLLECTION_NAME_STOCK_ENTRY = 'stockEntries';

    constructor(private readonly authenticationService: AuthenticationService) {}

    getAllItems(): Observable<Item[]> {
        const itemQuery = query(collection(this.db, this.COLLECTION_NAME_INVENTORY), where('deleted', '==', false));
        return from(
            getDocsFromServer(itemQuery).then((querySnapshot) => {
                const items: Item[] = [];
                querySnapshot.forEach((doc) => {
                    const { name, unit, quantity, points, remarks } = doc.data();
                    items.push({ id: doc.id, name, unit, quantity: Math.round(quantity), points, remarks });
                });
                return items;
            })
        );
    }

    addNewItem(item: ItemVariable): Observable<Item> {
        const [_, uid] = this.authenticationService.getUserUidAndRole();
        return from(
            addDoc(collection(this.db, this.COLLECTION_NAME_INVENTORY), {
                ...item,
                createdBy: uid,
                deleted: false,
            }).then((docRef) => ({ id: docRef.id, ...item }))
        );
    }

    updateItem(id: string, name: string, unit: ItemUnit, points: number, remarks: string): Observable<boolean> {
        const docRef = doc(this.db, this.COLLECTION_NAME_INVENTORY, id);
        return from(updateDoc(docRef, { name, unit, points, remarks }).then((_) => true));
    }

    updateItemQuantity(id: string, updatedQuantity: number): Observable<Item> {
        const docRef = doc(this.db, this.COLLECTION_NAME_INVENTORY, id);
        return from(
            updateDoc(docRef, { quantity: increment(updatedQuantity) }).then((_) =>
                getDocFromServer(docRef).then((doc) => {
                    const { name, unit, quantity, points, remarks } = doc.data();
                    return { id, name, unit, quantity, points, remarks };
                })
            )
        );
    }

    deleteItem(id: string): Observable<boolean> {
        const docRef = doc(this.db, this.COLLECTION_NAME_INVENTORY, id);
        return from(updateDoc(docRef, { deleted: true }).then((_) => true));
    }

    addStockEntry(input: StockEntry): Observable<boolean> {
        const [_, uid] = this.authenticationService.getUserUidAndRole();
        return from(
            addDoc(collection(this.db, this.COLLECTION_NAME_STOCK_ENTRY), { ...input, createdBy: uid }).then(
                (_) => true
            )
        );
    }
}
