import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ClientsDataService } from './clients.data.service';
import { Client } from './clients.model';
import * as _ from 'lodash';
import { ContractorsService } from '../contractors/contractors.service';

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    private clients: Client[] = [];
    private allClients: BehaviorSubject<Client[]> = new BehaviorSubject(this.clients);
    private isClientsLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);

    allClients$ = this.allClients.asObservable();
    isClientsLoading$ = this.isClientsLoading.asObservable();

    constructor(private readonly clientsDataService: ClientsDataService) {}

    getAllClients(): void {
        if (!this.clients.length) {
            this.isClientsLoading.next(true);
            this.clientsDataService
                .getClients()
                .pipe(first())
                .subscribe((clients) => {
                    this.clients = _.sortBy(clients, (x) => x.name.toLowerCase());
                    this.allClients.next(this.clients);
                    this.isClientsLoading.next(false);
                });
        }
    }

    getClientById(clientId: string): Observable<Client> {
        return of(this.clients.find((client) => client.id === clientId));
    }

    addClient(name: string, mobileNo: string, salesPerson: string, amount: number, contractorId: string): void {
        this.clientsDataService
            .addClient(name, mobileNo, salesPerson, amount, contractorId)
            .pipe(first())
            .subscribe((client) => {
                this.clients.push(client);
                this.clients = _.sortBy(this.clients, (x) => x.name.toLowerCase());
                this.allClients.next(this.clients);
            });
    }

    updateClient(
        id: string,
        name: string,
        mobileNo: string,
        salesPerson: string,
        amount: number,
        contractorId: string
    ): void {
        this.clientsDataService
            .updateClient(id, name, mobileNo, salesPerson, amount, contractorId)
            .pipe(first())
            .subscribe((updatedClient) => {
                const existingClientIndex = this.clients.findIndex((client) => client.id === id);
                this.clients[existingClientIndex] = updatedClient;
                this.clients = _.sortBy(this.clients, (x) => x.name.toLowerCase());
                this.allClients.next(this.clients);
            });
    }

    updateClientAmount(id: string, newAmount: number, oldAmount?: number): void {
        let { name, mobileNo, salesPerson, amount } = this.clients.find((client) => client.id === id);
        if (oldAmount) {
            amount += newAmount - oldAmount;
            newAmount = amount;
        } else {
            newAmount += amount;
        }
        // this.updateClient(id, name, mobileNo, salesPerson, newAmount);
    }

    deleteClient(id: string): void {
        this.clientsDataService
            .deleteClient(id)
            .pipe(first())
            .subscribe((deleted) => {
                if (deleted) {
                    const existingClientIndex = this.clients.findIndex((client) => client.id === id);
                    if (existingClientIndex > -1) {
                        this.clients.splice(existingClientIndex, 1);
                        this.clients = _.sortBy(this.clients, (x) => x.name.toLowerCase());
                        this.allClients.next(this.clients);
                    }
                }
            });
    }
}
