import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { FileManagerDataService } from './file-manager.data.service';
import { FilesAndFolders } from './file-manager.model';

@Injectable({
    providedIn: 'root',
})
export class FileManagerService {
    private filesAndFolders: Map<string, FilesAndFolders[]> = new Map();
    private allFilesAndFolders: BehaviorSubject<FilesAndFolders[]> = new BehaviorSubject([]);
    private isFilesAndFoldersLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);

    allFilesAndFolders$ = this.allFilesAndFolders.asObservable();
    isFilesAndFoldersLoading$ = this.isFilesAndFoldersLoading.asObservable();

    constructor(private readonly fileManagerDataService: FileManagerDataService) {}

    getFilesAndFolder(parentFolderId: string): void {
        this.isFilesAndFoldersLoading.next(true);
        if (!this.filesAndFolders.has(parentFolderId)) {
            this.fileManagerDataService
                .getFilesAndFolders(parentFolderId)
                .pipe(first())
                .subscribe((fetchFilesAndFolder) => {
                    this.filesAndFolders.set(parentFolderId, fetchFilesAndFolder);
                    this.allFilesAndFolders.next(fetchFilesAndFolder);
                    this.isFilesAndFoldersLoading.next(false);
                });
        } else {
            this.allFilesAndFolders.next(this.filesAndFolders.get(parentFolderId));
            this.isFilesAndFoldersLoading.next(false);
        }
    }

    addNewFolder(folderName: string, parentFolderId: string): void {
        this.fileManagerDataService
            .addFolder(folderName, parentFolderId)
            .pipe(first())
            .subscribe((data) => {
                const existingFilesAndFolders = this.filesAndFolders.get(parentFolderId);
                existingFilesAndFolders.push(data);
                this.filesAndFolders.set(parentFolderId, existingFilesAndFolders);
                this.allFilesAndFolders.next(existingFilesAndFolders);
            });
    }

    addNewFiles(files: File[], parentFolderId: string, fullPath: string): void {
        const existingFilesAndFolders = this.filesAndFolders.get(parentFolderId);
        this.fileManagerDataService
            .addFiles(files, parentFolderId, fullPath)
            .pipe(first())
            .subscribe((data) => {
                existingFilesAndFolders.push(...data);
                this.filesAndFolders.set(parentFolderId, existingFilesAndFolders);
                this.allFilesAndFolders.next(existingFilesAndFolders);
            });
    }
}
