<div mat-dialog-content class="custom-dialog-content">
    <form class="flex flex-col bg-card shadow rounded overflow-hidden" [formGroup]="updateContractorPointFrom">
        <div class="bg-gray-50 dark:bg-gray-700 p-8 border-b">
            <p class="text-lg font-medium">Contractor Point Entries</p>
            <p class="text-secondary">Enter the below information for entering contractor points.</p>
        </div>
        <div class="flex flex-col p-8">
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Contractor</mat-label>
                <mat-select formControlName="contractor" required>
                    <mat-option *ngFor="let contractor of allContractors" [value]="contractor.id">{{
                        contractor.name
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Site Name</mat-label>
                <input matInput formControlName="siteName" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Date</mat-label>
                <input type="date" matInput formControlName="date" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Item</mat-label>
                <mat-select formControlName="item" (selectionChange)="updatePoints()" required>
                    <mat-option *ngFor="let item of allItems" [value]="item.id">{{ item.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Quantity</mat-label>
                <input matInput formControlName="quantity" (input)="countTotalPoints($event.target.value)" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Per Item Point</mat-label>
                <input matInput formControlName="points" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Total Points</mat-label>
                <input matInput formControlName="totalPoints" required />
            </mat-form-field>
        </div>

        <div class="flex items-center justify-end border-t px-8 py-5 bg-gray-50 dark:bg-gray-700">
            <button mat-button mat-dialog-close>Cancel</button>
            <button
                class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'"
                [disabled]="!updateContractorPointFrom.valid"
                (click)="updateContractorPoint()"
            >
                <span *ngIf="!updateContractorPointFrom.disabled"> Save </span>
                <mat-progress-spinner
                    *ngIf="updateContractorPointFrom.disabled"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
            </button>
        </div>
    </form>
</div>
