import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/core.state';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { UserDataService } from '../user.data.service';
import {
    ActionGetAllUsersDetailsFailed,
    ActionGetAllUsersDetailsStart,
    ActionGetAllUsersDetailsSuccess,
    ActionGetUserDetailsByUserIdFailed,
    ActionGetUserDetailsByUserIdStart,
    ActionGetUserDetailsByUserIdSuccess,
} from './user.actions';

@Injectable({
    providedIn: 'root',
})
export class UserEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store<AppState>,
        private readonly userDataServices: UserDataService
    ) {}

    getUserDetailsByUserId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionGetUserDetailsByUserIdStart),
            filter(({ userId }) => !!userId),
            first(),
            switchMap(({ userId }) =>
                this.userDataServices.getUserDetailsByUserId(userId).pipe(
                    first(),
                    switchMap((userDetails) => [ActionGetUserDetailsByUserIdSuccess({ userDetails })]),
                    catchError((error) => [ActionGetUserDetailsByUserIdFailed({ userError: error })])
                )
            )
        )
    );

    getAllUsersDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionGetAllUsersDetailsStart),
            first(),
            switchMap(() =>
                this.userDataServices.getAllUsersDetails().pipe(
                    first(),
                    switchMap((userDetails) => [ActionGetAllUsersDetailsSuccess({ userDetails })]),
                    catchError((error) => [ActionGetAllUsersDetailsFailed({ userError: error })])
                )
            )
        )
    );
}
