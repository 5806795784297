export const commonConfig = {
    appId: 'demo-sales-pro-webapp',
    appName: 'Nightly - Demo Sales Pro',
    domainName: 'nightly.demosales.co.in',
    firebaseConfig: {
        apiKey: 'AIzaSyA3FDh8L8Izr-bQpZgShGqPPD2OHOKNuf8',
        authDomain: 'nightly-demo-sales-pro.firebaseapp.com',
        projectId: 'nightly-demo-sales-pro',
        storageBucket: 'nightly-demo-sales-pro.appspot.com',
        messagingSenderId: '392462132189',
        appId: '1:392462132189:web:3da7c0f449f316347c8c34',
        measurementId: 'G-1DMZ5HCWQ8',
    },
    serviceUrl: 'https://asia-south1-nightly-demo-sales-pro.cloudfunctions.net/v1',
    api: {
        createUser: '/user/create',
        sendsms: '/sendsms',
    },
};
