<div mat-dialog-content class="custom-dialog-content">
    <form class="flex flex-col bg-card shadow rounded overflow-hidden" [formGroup]="updateItemForm">
        <div class="bg-gray-50 dark:bg-gray-700 p-8 border-b">
            <p class="text-lg font-medium">Update an item</p>
            <p class="text-secondary">Enter the below information for updating an item.</p>
        </div>
        <div class="flex flex-col p-8">
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Points - (Used for contractor point entry)</mat-label>
                <input matInput formControlName="points" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Unit</mat-label>
                <mat-select formControlName="unit" required>
                    <mat-option *ngFor="let unit of units" [value]="unit">{{ unit }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Remarks</mat-label>
                <input matInput formControlName="remarks" />
            </mat-form-field>
        </div>

        <div class="flex items-center justify-end border-t px-8 py-5 bg-gray-50 dark:bg-gray-700">
            <button mat-button mat-dialog-close>Cancel</button>
            <button
                class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'"
                [disabled]="!updateItemForm.valid"
                (click)="updateItem()"
            >
                <span *ngIf="!updateItemForm.disabled"> Save </span>
                <mat-progress-spinner
                    *ngIf="updateItemForm.disabled"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
            </button>
        </div>
    </form>
</div>
