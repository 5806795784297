import { AuthenticationState } from './authentication.model';
import { Action, createReducer, on } from '@ngrx/store';
import {
    ActionLoginWithUsernameAndPasswordFailed,
    ActionLoginWithUsernameAndPasswordSuccess,
    ActionLogoutFailed,
    ActionLogoutStart,
    ActionSetAuthError,
    ActionSetIsAuthenticating,
    ActionSetIsLoggedIn,
    ActionSetRedirectUrl,
    ActionSetRefreshToken,
    ActionSetToken,
} from './authentication.actions';

export const initialState: AuthenticationState = {
    isLoggedIn: false,
    token: null,
    refreshToken: null,
};

const reducer = createReducer(
    initialState,
    on(ActionLoginWithUsernameAndPasswordSuccess, (state) => ({
        ...state,
        isLoggedIn: true,
        isAuthenticating: false,
    })),
    on(ActionLoginWithUsernameAndPasswordFailed, (state, { authError }) => ({
        ...state,
        authError,
        isLoggedIn: false,
        isAuthenticating: false,
    })),
    on(ActionSetIsLoggedIn, (state, { isLoggedIn }) => ({ ...state, isLoggedIn })),
    on(ActionSetIsAuthenticating, (state, { isAuthenticating }) => ({ ...state, isAuthenticating })),
    on(ActionLogoutStart, (state) => ({ ...state, isLoggedIn: false, isAuthenticating: false })),
    on(ActionLogoutFailed, (state, { authError }) => ({ ...state, authError, isAuthenticating: false })),
    on(ActionSetToken, (state, { token }) => ({ ...state, token })),
    on(ActionSetRefreshToken, (state, { refreshToken }) => ({ ...state, refreshToken })),
    on(ActionSetRedirectUrl, (state, { redirectUrl }) => ({ ...state, redirectUrl })),
    on(ActionSetAuthError, (state, { authError }) => ({ ...state, authError }))
);

export function authenticationReducer(state: AuthenticationState, action: Action): AuthenticationState {
    return reducer(state, action);
}
