import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'app/core/clients/clients.model';
import { ClientsService } from 'app/core/clients/clients.service';
import {
    InvoiceType,
    InvoiceVariables,
} from 'app/core/invoices/invoices.model';
import { InvoicesService } from 'app/core/invoices/invoices.service';
import { ReplaySubject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { CreateClientComponent } from '../create-client/create-client.component';
import * as moment from 'moment';
import { InventoryService } from 'app/core/inventory/inventory.service';
import { Item } from 'app/core/inventory/inventory.model';
import { ContractorsService } from 'app/core/contractors/contractors.service';
import { PointEntriesVariables } from 'app/core/point-entries/point-entries.model';
import { PointEntriesService } from 'app/core/point-entries/point-entries.service';

@Component({
    selector: 'app-create-invoice',
    templateUrl: './create-invoice.component.html',
    styleUrls: ['./create-invoice.component.scss'],
})
export class CreateInvoiceComponent implements OnInit {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    addNewInvoiceForm: FormGroup;
    days = new Array(120);
    fileToUpload: File = null;
    uploadBtnText = 'Upload Challen';
    allItems: Item[] = [];
    allClients: Client[] = [];
    invoiceType = [InvoiceType.Sales, InvoiceType.Purchase];

    allClients$ = this.clientsService.allClients$;
    allItems$ = this.inventoryService.allItems$;

    constructor(
        private readonly clientsService: ClientsService,
        private readonly invoicesService: InvoicesService,
        private readonly inventoryService: InventoryService,
        private readonly contractorsService: ContractorsService,
        private readonly pointEntriesService: PointEntriesService,
        private readonly formBuilder: FormBuilder,
        public readonly dialog: MatDialog
    ) {
        this.addNewInvoiceForm = this.formBuilder.group({
            invoiceNo: ['', [Validators.required]],
            invoiceType: [this.invoiceType[0], [Validators.required]],
            date: ['', [Validators.required]],
            dueDate: ['', [Validators.required]],
            clientId: ['', [Validators.required]],
            remarks: [''],
            items: this.formBuilder.array([this.addMoreInvoiceItem()]),
        });
    }

    ngOnInit(): void {
        this.allItems$.pipe(takeUntil(this.destroyed$)).subscribe((items) => {
            this.allItems = items;
        });
        this.allClients$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((clients) => {
                this.allClients = clients;
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    addMoreItem(): void {
        this.itemsInvoiceArray.push(this.addMoreInvoiceItem());
    }

    removeItemInvoice(index: number): void {
        if (this.itemsInvoiceArray.length > 1) {
            this.itemsInvoiceArray.removeAt(index);
        }
    }

    addMoreInvoiceItem(): FormGroup {
        return this.formBuilder.group({
            itemId: ['', [Validators.required]],
            quantity: ['', [Validators.required]],
            price: [0, [Validators.required]],
            totalPrice: [{ value: 0, disabled: true }, [Validators.required]],
        });
    }

    get itemsInvoiceArray(): FormArray {
        return <FormArray>this.addNewInvoiceForm.get('items');
    }

    countInvoicePerItemTotalFromQuantity(
        quantity: number,
        parentIndex: number
    ) {
        if (!isNaN(quantity)) {
            const price =
                this.itemsInvoiceArray.controls[parentIndex].value.price;
            // TODO: Look at this area later
            // (this.itemsInvoiceArray.controls[parentIndex] as any).controls.price.patchValue(price);
            (
                this.itemsInvoiceArray.controls[parentIndex] as any
            ).controls.totalPrice.patchValue(price * quantity);
        }
    }

    countInvoicePerItemTotalFromPrice(price: number, parentIndex: number) {
        if (!isNaN(price)) {
            const quantity =
                this.itemsInvoiceArray.controls[parentIndex].value.quantity;
            (
                this.itemsInvoiceArray.controls[parentIndex] as any
            ).controls.totalPrice.patchValue(price * quantity);
        }
    }

    handleFileInput(files: FileList) {
        if (files[0].type === 'image/jpeg' || files[0].type === 'image/png') {
            this.fileToUpload = files[0];
            this.uploadBtnText = this.fileToUpload.name;
        } else {
            alert('Please select JPEG or PNG file!');
        }
    }

    get totalQuantityAndAmount(): { quantity: number; amount: number } {
        const { items } = this.addNewInvoiceForm.value;
        let amount = 0;
        let quantity = 0;
        items.forEach((item) => {
            if (
                !isNaN(Number.parseFloat(item.price)) &&
                !isNaN(Number.parseFloat(item.quantity))
            ) {
                amount +=
                    Number.parseFloat(item.price) *
                    Number.parseFloat(item.quantity);
                quantity += Number.parseFloat(item.quantity);
            }
        });
        return {
            quantity,
            amount,
        };
    }

    addInvoice(): void {
        this.addNewInvoiceForm.disable();
        const {
            invoiceNo,
            date,
            dueDate,
            clientId,
            remarks,
            items,
            invoiceType,
        } = this.addNewInvoiceForm.value;
        let amount = 0;
        let totalPoints = 0;
        items.forEach((item) => {
            totalPoints +=
                this.allItems.find((i) => i.id === item.itemId).points *
                item.quantity;
            amount += item.totalPrice;
            item.quantity = Number.parseFloat(item.quantity);
            item.price = Number.parseFloat(item.price);
        });
        const input: InvoiceVariables = {
            amount,
            authorizedByAdmin: false,
            authorizedBySales: false,
            client: clientId,
            date: new Date(date).toISOString(),
            dueDate: moment(date).add(dueDate, 'days').toISOString(),
            fileName: '',
            filePath: '',
            invoiceNo,
            invoiceItems: items,
            paid: false,
            remarks,
            receivedAmounts: [],
            invoiceType,
        };
        this.sendContractorSms(
            totalPoints,
            clientId,
            invoiceNo,
            new Date(date).toISOString()
        );
        this.invoicesService.addInvoice(input, this.fileToUpload);
        // this.clientsService.updateClientAmount(clientId, amount);
    }

    public openCreateClientDialog(): void {
        this.dialog.open(CreateClientComponent, {
            disableClose: true,
        });
    }

    private sendContractorSms(
        totalPoints: number,
        clientId: string,
        invoiceNo: string,
        date: string
    ): void {
        const client = this.allClients.find((c) => c.id === clientId);
        if (client.contractorId) {
            this.contractorsService
                .getContractorById(client.contractorId)
                .pipe(first())
                .subscribe((contractor) => {
                    if (contractor) {
                        const pointEntry: PointEntriesVariables = {
                            contractorId: contractor.id,
                            date: new Date(date).toISOString(),
                            invoiceId: invoiceNo,
                            quantity: 0,
                            points: totalPoints,
                            clientName: client.name,
                            totalPoints,
                        };
                        // const mobileNo = contractor.mobileNo;
                        this.pointEntriesService.addPointEntryForInvoice(
                            pointEntry,
                            contractor
                        );
                        // this.contractorsService.sendContractorInvoiceSms(
                        //     mobileNo,
                        //     invoiceNo,
                        //     totalPoints,
                        //     date,
                        //     client.name
                        // );
                    }
                });
        }
    }
}
