import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileManagerService } from 'app/core/file-manager/file-manager.service';

@Component({
    selector: 'app-create-folder',
    templateUrl: './create-folder.component.html',
    styleUrls: ['./create-folder.component.scss'],
})
export class CreateFolderComponent {
    addNewFolderForm: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: string,
        private readonly fileManagerService: FileManagerService
    ) {
        this.addNewFolderForm = new FormGroup({
            name: new FormControl('', [Validators.required]),
        });
    }

    addClient(): void {
        this.addNewFolderForm.disable();
        const { name } = this.addNewFolderForm.value;
        this.fileManagerService.addNewFolder(name, this.data);
    }
}
