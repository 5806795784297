import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientsService } from 'app/core/clients/clients.service';
import { Contractor } from 'app/core/contractors/contractors.model';
import { UserDetails } from 'app/core/user/store/user.model';
import { UserService } from 'app/core/user/user.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContractorsService } from 'app/core/contractors/contractors.service';

@Component({
    selector: 'app-create-client',
    templateUrl: './create-client.component.html',
    styleUrls: ['./create-client.component.scss'],
})
export class CreateClientComponent implements OnInit, OnDestroy {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    allUsers: UserDetails[];
    allContractors: Contractor[];
    addNewClient: FormGroup;

    constructor(
        private readonly userService: UserService,
        private readonly clientsService: ClientsService,
        private readonly contractorsService: ContractorsService
    ) {
        this.addNewClient = new FormGroup({
            name: new FormControl('', [Validators.required]),
            mobileNo: new FormControl('', [Validators.required]),
            amount: new FormControl(0, [Validators.required]),
            salesPerson: new FormControl('', [Validators.required]),
            contractorId: new FormControl(''),
        });
    }

    ngOnInit(): void {
        this.userService.allUsers$.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
            this.allUsers = users;
        });
        this.contractorsService.allContractors$.pipe(takeUntil(this.destroyed$)).subscribe((contractors) => {
            this.allContractors = contractors;
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    addClient(): void {
        this.addNewClient.disable();
        const { name, mobileNo, amount, salesPerson, contractorId } = this.addNewClient.value;
        this.clientsService.addClient(name, mobileNo, salesPerson, Number.parseFloat(amount), contractorId);
    }
}
