<div mat-dialog-content class="custom-dialog-content">
    <form class="flex flex-col bg-card shadow rounded overflow-hidden" [formGroup]="addInvoiceAmount">
        <div class="bg-gray-50 dark:bg-gray-700 p-8 border-b">
            <p class="text-lg font-medium">Invoice payment entry</p>
            <!-- <p class="text-secondary">Enter the below information for creating a new client.</p> -->
        </div>
        <div class="flex flex-col p-8">
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Invoice No.</mat-label>
                <input matInput [value]="data.invoiceNo" disabled />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Party Name</mat-label>
                <input matInput [value]="data.clientName" disabled />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Amount</mat-label>
                <input matInput formControlName="amount" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Date</mat-label>
                <input type="date" matInput formControlName="date" required />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Type</mat-label>
                <mat-select formControlName="type" required>
                    <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Cheque No (Optional)</mat-label>
                <input matInput formControlName="chequeNo" />
            </mat-form-field>
            <mat-form-field class="fuse-mat-dense flex-auto">
                <mat-label>Remark (Optional)</mat-label>
                <input matInput formControlName="remarks" />
            </mat-form-field>
        </div>

        <div class="flex items-center justify-end border-t px-8 py-5 bg-gray-50 dark:bg-gray-700">
            <button mat-button mat-dialog-close>Cancel</button>
            <button
                class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'"
                [disabled]="!addInvoiceAmount.valid"
                (click)="addClient()"
            >
                <span *ngIf="!addInvoiceAmount.disabled"> Save </span>
                <mat-progress-spinner
                    *ngIf="addInvoiceAmount.disabled"
                    [diameter]="24"
                    [mode]="'indeterminate'"
                ></mat-progress-spinner>
            </button>
        </div>
    </form>
</div>
