import { Injectable } from '@angular/core';
import { getAuth } from 'firebase/auth';
import {
    addDoc,
    collection,
    doc,
    getDocsFromServer,
    getFirestore,
    query,
    updateDoc,
    where,
    writeBatch,
} from 'firebase/firestore';
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    uploadBytes,
} from 'firebase/storage';
import { from, Observable, of } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserRoles } from '../authentication/store/authentication.model';
import { Invoice, InvoiceReceive, InvoiceVariables } from './invoices.model';

@Injectable({
    providedIn: 'root',
})
export class InvoicesDataService {
    private db = getFirestore();
    private storage = getStorage();
    private COLLECTION_NAME = 'invoices';

    constructor(
        private readonly authenticationService: AuthenticationService
    ) {}

    getInvoices(): Observable<Invoice[]> {
        const [role, uid] = this.authenticationService.getUserUidAndRole();
        let invoiceQuery = query(collection(this.db, this.COLLECTION_NAME));
        if (role === UserRoles.ADMIN || role === UserRoles.SALESADMIN) {
            invoiceQuery = query(
                collection(this.db, this.COLLECTION_NAME),
                where('deleted', '==', false)
            );
        } else {
            invoiceQuery = query(
                collection(this.db, this.COLLECTION_NAME),
                where('deleted', '==', false),
                where('created', '==', uid)
            );
        }
        return from(
            getDocsFromServer(invoiceQuery).then((querySnapshot) => {
                const invoices: Invoice[] = [];
                querySnapshot.forEach((doc) => {
                    const {
                        amount,
                        authorizedByAdmin,
                        authorizedBySales,
                        client,
                        date,
                        dueDate,
                        fileName,
                        filePath,
                        invoiceNo,
                        invoiceItems,
                        paid,
                        remarks,
                        createdBy,
                        receivedAmounts,
                        invoiceType,
                    } = doc.data();
                    invoices.push({
                        id: doc.id,
                        amount,
                        authorizedByAdmin,
                        authorizedBySales,
                        client,
                        date,
                        dueDate,
                        fileName,
                        filePath,
                        invoiceNo,
                        invoiceItems,
                        paid,
                        remarks,
                        createdBy,
                        receivedAmounts: receivedAmounts || [],
                        invoiceType,
                    });
                });
                return invoices;
            })
        );
    }

    addInvoice(input: InvoiceVariables): Observable<Invoice> {
        const [_, uid] = this.authenticationService.getUserUidAndRole();
        return from(
            addDoc(collection(this.db, this.COLLECTION_NAME), {
                ...input,
                createdBy: uid,
                deleted: false,
            }).then((docRef) => ({
                id: docRef.id,
                createdBy: uid,
                deleted: false,
                ...input,
            }))
        );
    }

    updateInvoice(id: string, input: InvoiceVariables): Observable<Invoice> {
        const [_, uid] = this.authenticationService.getUserUidAndRole();
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        return from(
            updateDoc(docRef, { ...input }).then((_) => ({
                id,
                createdBy: uid,
                deleted: false,
                ...input,
            }))
        );
    }

    deleteInvoice(id: string): Observable<boolean> {
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        return from(
            updateDoc(docRef, {
                deleted: true,
                deletedTime: new Date().toISOString(),
            }).then((_) => true)
        );
    }

    deleteBulkInvoices(ids: string[]): Observable<boolean> {
        const batch = writeBatch(this.db);
        ids.forEach((id) => {
            const docRef = doc(this.db, this.COLLECTION_NAME, id);
            batch.update(docRef, {
                deleted: true,
                deletedTime: new Date().toISOString(),
            });
        });
        return from(batch.commit().then((_) => true));
    }

    markInvoiceAsPaid(id: string): Observable<boolean> {
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        return from(updateDoc(docRef, { paid: true }).then((_) => true));
    }

    addInvoiceReceivedAmount(
        invoiceId: string,
        data: InvoiceReceive[]
    ): Observable<boolean> {
        const docRef = doc(this.db, this.COLLECTION_NAME, invoiceId);
        return from(
            updateDoc(docRef, {
                receivedAmounts: data,
            }).then((_) => true)
        );
    }

    markInvoiceAsAuthorize(id: string): Observable<boolean> {
        const [role] = this.authenticationService.getUserUidAndRole();
        const docRef = doc(this.db, this.COLLECTION_NAME, id);
        if (role === UserRoles.ADMIN || role === UserRoles.SALESADMIN) {
            return from(
                updateDoc(docRef, { authorizedByAdmin: true }).then((_) => true)
            );
        } else {
            return from(
                updateDoc(docRef, { authorizedBySales: true }).then((_) => true)
            );
        }
    }

    uploadFile(
        file: File,
        invoiceNo: string,
        clientId: string
    ): Observable<string[]> {
        const metadata = {
            contentType: 'image/jpeg',
        };
        const storageRef = ref(
            this.storage,
            `invoices/${clientId}/${invoiceNo}` + file.name
        );
        return from(
            uploadBytes(storageRef, file).then((snapshot) =>
                getDownloadURL(snapshot.ref).then((downloadUrl) => [
                    file.name,
                    downloadUrl,
                ])
            )
        );
    }
}
