import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contractor } from 'app/core/contractors/contractors.model';
import { ContractorsService } from 'app/core/contractors/contractors.service';
import { Item } from 'app/core/inventory/inventory.model';
import { InventoryService } from 'app/core/inventory/inventory.service';
import { PointEntries } from 'app/core/point-entries/point-entries.model';
import { PointEntriesService } from 'app/core/point-entries/point-entries.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-update-point-entries',
    templateUrl: './update-point-entries.component.html',
    styleUrls: ['./update-point-entries.component.scss'],
})
export class UpdatePointEntriesComponent implements OnInit {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    allContractors: Contractor[];
    allItems: Item[];
    updateContractorPointFrom: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PointEntries,
        private readonly inventoryService: InventoryService,
        private readonly contractorsService: ContractorsService,
        private readonly pointEntriesService: PointEntriesService
    ) {
        this.updateContractorPointFrom = new FormGroup({
            contractor: new FormControl(data.contractorId, [Validators.required]),
            siteName: new FormControl(data.siteName, [Validators.required]),
            date: new FormControl(new Date(data.date), [Validators.required]),
            item: new FormControl(data.itemId, [Validators.required]),
            quantity: new FormControl(data.quantity, [Validators.required]),
            points: new FormControl({ value: data.points, disabled: true }, [Validators.required]),
            totalPoints: new FormControl({ value: data.totalPoints, disabled: true }, [Validators.required]),
        });
    }

    ngOnInit(): void {
        this.inventoryService.allItems$.pipe(takeUntil(this.destroyed$)).subscribe((items) => {
            this.allItems = items;
        });
        this.contractorsService.allContractors$.pipe(takeUntil(this.destroyed$)).subscribe((contractors) => {
            this.allContractors = contractors;
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    updatePoints(): void {
        const item = this.allItems.find((i) => i.id === this.updateContractorPointFrom.controls['item'].value);
        this.updateContractorPointFrom.controls['points'].patchValue(item.points);
    }

    countTotalPoints(quantity: number) {
        if (!isNaN(quantity)) {
            const points = this.updateContractorPointFrom.controls['points'].value;
            this.updateContractorPointFrom.controls['totalPoints'].patchValue(Number(points * quantity).toFixed(2));
        }
    }

    updateContractorPoint(): void {
        this.updateContractorPointFrom.disable();
        const { contractor, siteName, date, item, quantity, points, totalPoints } =
            this.updateContractorPointFrom.value;
        this.pointEntriesService.updatePointEntry(this.data.id, {
            contractorId: contractor,
            siteName,
            date: new Date(date).toISOString(),
            itemId: item,
            quantity,
            points,
            totalPoints: +totalPoints,
        });
    }
}
